import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import {
  DialogHeader,
  DialogSection,
  IOption,
  IOptionExtra,
  Notice,
  NoticeStack,
  Nullable,
  Paper,
  ToggleButton,
} from '@linetweet/linetweet-ui';
import { Controller, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { Box, Dialog, DialogContent, FormHelperText, Grid, Stack, ToggleButtonGroup, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { IntlShape, useIntl } from 'react-intl';
import ReactGA from 'react-ga4';

import { TimeHelper } from 'utils';
import { CalendarEventTypeEnum, ICreateCalendarEventQueryParams, Service, StoreSettings } from 'types';
import { AsyncButton, EmployeeSelectField, Spin } from 'features/commons';
import { createCalendarEvents } from 'store/appointments/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getSlots, getSlotsByDate, getSwapSlots } from 'store/slots/thunks';
import { fetchEmployees } from 'store/employees/thunks';
import { selectAvailableDates } from 'store/slots/selectors';
import { resetDaySlots, resetSlots, resetSwapSlots } from 'store/slots/slice';
import { IGetSlotsByDatesPayload } from 'store/slots/types';
import {
  AppointmentFormCustomerSection,
  AppointmentFormInformationSection,
  AppointmentFormServiceSection,
  AppointmentFormTimeSection,
  PreferredSlotSelectSection,
} from './components';
import { createSlotOptions, getDateRange } from './helpers';
import {
  AppointmentFormSlotItem,
  CalendarEventCreateParams,
  CalendarEventFormServicesSectionProps,
  CalendarEventFormSuggestedEmployeeInputProps,
  CreateCalendarEventFormProps,
  CreateCalendarEventSharedValues,
  DateInputProps,
  IAppointmentFormValues,
  IBlockerFormValues,
  IBossAppointmentFormValues,
  IBreakFormValues,
  ServiceTabsSelectOption,
} from './types';
import { selectAvailableEmployees, selectSlotEmployeesByServiceIds } from '../../store/employees/selectors';
import { selectCurrentStore, selectStoreServices } from '../../store/store/selectors';
import { CustomDay } from './subcomponents';
import { resetCreateCalendarEvent } from '../../store/appointments/slice';
import { appointmentFormSchema } from './util/appointmentFormSchema';
import { breakFormSchema } from './util/breakFormSchema';
import { blockerFormSchema } from './util/blockerFormSchema';
import { bossAppointmentFormSchema } from './util/bossAppointmentFromSchema';
import { createEmployeeOptions } from './util/createEmployeeOptions';
import { isRequired } from './util/isRequired';
import { createSwapSlotOptions } from './util/createSwapSlotOptions';

import styles from './CreateCalendarEvent.module.scss';

type Props = {
  onClose: () => void;
  params: CalendarEventCreateParams;
};

const SLOT_FILTER_FIELDS = ['date', 'services', 'employee'];

const appointmentTypeOptions: (IOption & IOptionExtra<{ disabled?: boolean }>)[] = [
  {
    value: CalendarEventTypeEnum.APPOINTMENT,
    label: 'appointment.appointment',
  },
  {
    value: CalendarEventTypeEnum.BREAK,
    label: 'appointment.break',
  },
  {
    value: CalendarEventTypeEnum.BLOCKER,
    label: 'appointment.blocker',
  },
  {
    value: CalendarEventTypeEnum.BOSS_APPOINTMENT,
    label: 'appointment.bossAppointment',
  },
];

const getCreateAppointmentFormDefaultValues = (
  params: CalendarEventCreateParams,
  timezone: string,
  sharedValues: Partial<IAppointmentFormValues & IBreakFormValues & IBlockerFormValues>,
  intl: IntlShape,
): IAppointmentFormValues => {
  let dateValue: string;
  if (params.date) {
    dateValue = params.date;
  } else {
    dateValue = TimeHelper.toStandardFormat(TimeHelper.toDayjs(new Date(), timezone));
  }

  let slotValue: Nullable<AppointmentFormSlotItem> = null;
  if (params.time) {
    slotValue = { label: TimeHelper.getHHmmTimeFromMinutes(params.time), value: params.time };
  }

  let servicesValue: ServiceTabsSelectOption[] = [];
  if (params.services) {
    servicesValue = params.services.map((service) => ({
      label: intl.formatMessage({ id: service.name }),
      value: service.id,
      name: service.name,
      tabId: service.category,
      extra: { ...service },
    }));
  }

  const defaultValues = {
    date: sharedValues.date || dateValue,
    note: sharedValues.note || params.note || null,
    slot: sharedValues.slot || slotValue,
    gender: sharedValues.gender || params.gender || null,
    services: sharedValues.services || servicesValue,
    employee: sharedValues.employee || params.employeeId || null,
    title: sharedValues.title || params.title || null,
    lastName: sharedValues.lastName || params.lastName || '',
    firstName: sharedValues.firstName || params.firstName || '',
    email: sharedValues.email || params.email || '',
    phoneNumber: sharedValues.phoneNumber || params.phoneNumber || '',
    pinnedEmployee: sharedValues.pinnedEmployee || null,
  };

  return defaultValues;
};

const getCreateBossAppointmentFormDefaultValues = (
  params: CalendarEventCreateParams,
  timezone: string,
  sharedValues: Partial<IAppointmentFormValues & IBreakFormValues & IBlockerFormValues>,
  intl: IntlShape,
  storeSettings: StoreSettings,
): IBossAppointmentFormValues => {
  let dateValue: string;
  if (params.date) {
    dateValue = params.date;
  } else {
    dateValue = TimeHelper.toStandardFormat(TimeHelper.toDayjs(new Date(), timezone));
  }

  let servicesValue: ServiceTabsSelectOption[] = [];
  if (params.services) {
    servicesValue = params.services.map((service) => ({
      label: intl.formatMessage({ id: service.name }),
      value: service.id,
      name: service.name,
      tabId: service.category,
      extra: { ...service },
    }));
  }

  const servicesValues = (sharedValues.services || servicesValue).filter(
    (service) => storeSettings.bossMode?.services.indexOf(service.value) !== -1,
  );

  const bossModeDays = storeSettings.bossMode ? storeSettings.bossMode.days : 0;
  const availableDays = TimeHelper.getDaysFromTo(
    TimeHelper.toDayjs(new Date(), timezone),
    TimeHelper.addToDate(new Date(), timezone, bossModeDays),
  );
  const date =
    availableDays.indexOf(sharedValues.date || dateValue) !== -1
      ? sharedValues.date || dateValue
      : TimeHelper.toStandardFormat(TimeHelper.toDayjs(new Date(), timezone));

  return {
    date,
    note: sharedValues.note || params.note || null,
    gender: sharedValues.gender || params.gender || null,
    services: servicesValues,
    employee: sharedValues.employee || params.employeeId || null,
    title: sharedValues.title || params.title || null,
    lastName: sharedValues.lastName || params.lastName || '',
    firstName: sharedValues.firstName || params.firstName || '',
    email: sharedValues.email || params.email || '',
    phoneNumber: sharedValues.phoneNumber || params.phoneNumber || '',
    pinnedEmployee: sharedValues.pinnedEmployee || null,
    from: sharedValues.from || params.from || null,
  };
};

const getCreateBlockerFormDefaultValues = (
  params: CalendarEventCreateParams,
  timezone: string,
  sharedValues: Partial<IAppointmentFormValues & IBreakFormValues & IBlockerFormValues>,
): IBlockerFormValues => {
  let dateValue: string;
  if (params.date) {
    dateValue = params.date;
  } else {
    dateValue = TimeHelper.toStandardFormat(TimeHelper.toDayjs(new Date(), timezone));
  }

  const defaultValues = {
    date: sharedValues.date || dateValue,
    note: sharedValues.note || params.note || null,
    employee: sharedValues.employee || params.employeeId || null,
    title: params.title || null,
    from: sharedValues.from || params.from || null,
    to: sharedValues.to || params.to || null,
  };

  if (sharedValues.slot) {
    const duration = sharedValues.services?.reduce((acc, service) => acc + service.extra.duration, 0) || 60;

    defaultValues.from = sharedValues.slot.value;
    defaultValues.to = sharedValues.slot.value + duration;
  }

  return defaultValues;
};

const getCreateBreakFormDefaultValues = (
  params: CalendarEventCreateParams,
  timezone: string,
  sharedValues: Partial<IAppointmentFormValues & IBreakFormValues & IBlockerFormValues>,
  intl: IntlShape,
): IBreakFormValues => {
  let dateValue: string;
  if (params.date) {
    dateValue = params.date;
  } else {
    dateValue = TimeHelper.toStandardFormat(TimeHelper.toDayjs(new Date(), timezone));
  }

  const defaultValues = {
    date: sharedValues.date || dateValue,
    note: sharedValues.note || params.note || null,
    employee: sharedValues.employee || params.employeeId || null,
    title: sharedValues.title || params.title || intl.formatMessage({ id: 'appointment.break' }).toUpperCase(),
    recurring: sharedValues.recurring || params.recurring || null,
    from: sharedValues.from || params.from || null,
    to: sharedValues.to || params.to || null,
  };

  if (sharedValues.slot) {
    const duration = sharedValues.services?.reduce((acc, service) => acc + service.extra.duration, 0) || 60;

    defaultValues.from = sharedValues.slot.value;
    defaultValues.to = sharedValues.slot.value + duration;
  }

  return defaultValues;
};

// eslint-disable-next-line prefer-arrow-callback
const DateInput = React.memo(function DateInput({
  value: propsValue,
  onChange: propsOnChange,
  availableDays,
  disableNotHighlighted = false,
  timezone,
}: DateInputProps) {
  const minDate = useMemo(() => TimeHelper.dateTimeToDayjs(TimeHelper.toStandardFormat(TimeHelper.toDayjs(new Date(), timezone))), []);

  const onChange = useCallback((value) => {
    if (!value) return;

    propsOnChange(value.format('YYYY-MM-DD'));
  }, []);

  const renderDay = useCallback(
    (_, __, pickersDayProps) => (
      <CustomDay disableNotHighlighted={disableNotHighlighted} suggestedDays={availableDays} {...pickersDayProps} />
    ),
    [availableDays],
  );

  return <CalendarPicker date={TimeHelper.dateTimeToDayjs(propsValue)} minDate={minDate} renderDay={renderDay} onChange={onChange} />;
});

// eslint-disable-next-line prefer-arrow-callback
const CalendarEventFormDateInputWithSlots = React.memo(function CalendarEventFormDateInputWithSlots(props: DateInputProps) {
  const form = useFormContext();
  const dispatch = useAppDispatch();

  const servicesValue = useWatch({ control: form.control, name: 'services' });
  const employeeValue = useWatch({ control: form.control, name: 'employee' });
  const allServices = useAppSelector(selectStoreServices, shallowEqual);
  const store = useAppSelector(selectCurrentStore);

  const serviceIds = useMemo(() => servicesValue.map(({ value }) => value), [servicesValue]);

  const availableDays = useAppSelector((state) => selectAvailableDates(state, serviceIds, employeeValue), shallowEqual);
  const calendarSlotsState = useAppSelector((state) => state.slots.slots, shallowEqual);

  const prevSlotsPayload = useRef<Nullable<IGetSlotsByDatesPayload>>(null);
  useEffect(() => {
    const payload = {
      serviceIds: allServices
        .filter((service: Service) => serviceIds.includes(service.id))
        .map((service) => service.id)
        .sort(),
      dates: getDateRange(props.timezone, store.appSettings.slotsLeadingDays),
      timestamp: Date.now(),
      timezone: props.timezone,
    };

    let shouldFetch = !prevSlotsPayload.current;

    if (!shouldFetch && prevSlotsPayload.current!.dates[0] !== payload.dates[0]) {
      shouldFetch = true;
    }

    if (!shouldFetch && payload.serviceIds.length !== prevSlotsPayload.current!.serviceIds.length) {
      shouldFetch = true;
    }

    if (!shouldFetch) {
      for (let index = 0; index < payload.serviceIds.length; index += 1) {
        const payloadServiceId = payload.serviceIds[index];
        const prevPayloadServiceId = prevSlotsPayload.current!.serviceIds[index];

        if (payloadServiceId !== prevPayloadServiceId) {
          shouldFetch = true;
          break;
        }
      }
    }

    if (shouldFetch) {
      dispatch(getSlots(payload));

      prevSlotsPayload.current = payload;
    }
  }, [serviceIds, allServices, props.timezone, store]);

  useEffect(
    () => () => {
      dispatch(resetSlots());
    },
    [],
  );

  return (
    <Spin loading={calendarSlotsState.loading}>
      <DateInput {...props} availableDays={availableDays} />
    </Spin>
  );
});

// eslint-disable-next-line prefer-arrow-callback
const CalendarEventFormServicesSection = React.memo(function CalendarEventFormServicesSection({
  allowedServices = null,
}: CalendarEventFormServicesSectionProps) {
  const allServices = useAppSelector(selectStoreServices, shallowEqual);

  const servicesToShow = useMemo(() => {
    if (allowedServices === null) return allServices;

    return allServices.filter((service) => allowedServices.indexOf(service.id) !== -1);
  }, [allServices, allowedServices]);

  return <AppointmentFormServiceSection services={servicesToShow} />;
});

// eslint-disable-next-line prefer-arrow-callback
const CalendarEventFormSuggestedEmployeeInput = React.memo(function CalendarEventFormSuggestedEmployeeInput(
  props: CalendarEventFormSuggestedEmployeeInputProps,
) {
  const { value, onChange, onBlur, error, onOptionsFetch, required } = props;

  const dispatch = useAppDispatch();
  const form = useFormContext();

  const servicesValue = useWatch({ control: form.control, name: 'services' });
  const dateValue = useWatch({ control: form.control, name: 'date' });

  const serviceIds = useMemo(() => {
    if (!servicesValue) {
      return [];
    }

    return servicesValue.map((item) => item.value);
  }, [servicesValue]);

  const store = useAppSelector(selectCurrentStore);
  const availableEmployees = useAppSelector((state) => selectAvailableEmployees(state, serviceIds, dateValue), shallowEqual);
  const createCalendarEventState = useAppSelector((state) => state.appointments.createCalendarEvent, shallowEqual);

  useEffect(() => {
    onOptionsFetch(dateValue);
  }, [dateValue]);

  const employeeOptions = useMemo(
    () => (
      createEmployeeOptions({
        employeeId: value,
        storeEmployees: store.employees,
        availableEmployees,
      })
    ),
    [availableEmployees, value],
  );

  useEffect(() => {
    if (createCalendarEventState.error) {
      dispatch(
        getSlotsByDate({
          serviceIds,
          date: dateValue,
          timestamp: Date.now(),
          timezone: store.timezone,
        }),
      );
    }
  }, [createCalendarEventState.error]);

  useEffect(() => {
    form.trigger('employee');
  }, [servicesValue]);

  const fieldError = error || form.formState.errors.root?.employee;

  return (
    <EmployeeSelectField
      values={employeeOptions}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={!!fieldError}
      helperText={!['nullable', 'required'].includes(fieldError?.type as string) ? fieldError?.message : undefined}
      required={required}
    />
  );
});

type CalendarEventFormSlotInputProps = {
  value: AppointmentFormSlotItem | null;
  onChange: (value: AppointmentFormSlotItem | null) => void;
};

// eslint-disable-next-line prefer-arrow-callback
const CalendarEventFormSlotInput = React.memo(function CalendarEventFormSlotInput({ value, onChange }: CalendarEventFormSlotInputProps) {
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const form = useFormContext();

  const servicesValue = useWatch({ control: form.control, name: 'services' });
  const employeeValue = useWatch({ control: form.control, name: 'employee' });
  const slotValue = useWatch({ control: form.control, name: 'slot' });
  const dateValue = useWatch({ control: form.control, name: 'date' });

  const serviceIds = useMemo(() => servicesValue.map((item) => item.value), [servicesValue]);

  const allServices = useAppSelector(selectStoreServices, shallowEqual);
  const slotEmployees = useAppSelector((state) => selectSlotEmployeesByServiceIds(state, serviceIds, dateValue), shallowEqual);
  const store = useAppSelector(selectCurrentStore);
  const daySlotsState = useAppSelector((state) => state.slots.dateSlots, shallowEqual);
  const swapSlotsState = useAppSelector((state) => state.slots.swapSlots, shallowEqual);
  const createCalendarEventState = useAppSelector((state) => state.appointments.createCalendarEvent, shallowEqual);

  const slotOptions = useMemo<AppointmentFormSlotItem[]>(
    () => (
      createSlotOptions({
        timezone: store.timezone,
        employeeId: employeeValue,
        slots: daySlotsState.data || [],
      })
    ),
    [daySlotsState.data, store, employeeValue],
  );

  const swapSlotOptions = useMemo<AppointmentFormSlotItem[] | undefined>(
    () => (swapSlotsState.data ? createSwapSlotOptions(swapSlotsState.data, dateValue, store.timezone) : undefined),
    [swapSlotsState.data, dateValue, store],
  );

  const fetchSwapOptions = useCallback(() => {
    if (store.settings.swap && employeeValue) {
      dispatch(
        getSwapSlots({
          date: dateValue,
          employeeId: employeeValue,
          serviceIds,
          timestamp: Date.now(),
          timezone: store.timezone,
        }),
      );
    }
  }, [employeeValue, dateValue, employeeValue, serviceIds, store]);

  useEffect(() => {
    if (createCalendarEventState.error && swapSlotsState.data) {
      fetchSwapOptions();
    }
  }, [createCalendarEventState.error]);

  useEffect(() => {
    if (!serviceIds.length) {
      return;
    }

    dispatch(
      getSlotsByDate({
        serviceIds: allServices
          .filter((service: Service) => serviceIds.includes(service.id))
          .map((service) => service.id)
          .sort(),
        date: dateValue,
        timestamp: Date.now(),
        timezone: store.timezone,
      }),
    );
  }, [dateValue, serviceIds, allServices]);

  useEffect(() => {
    if (
      !daySlotsState.loading &&
      daySlotsState.data &&
      employeeValue &&
      (!slotEmployees.find(({ id }) => id === employeeValue) || !slotOptions.length)
    ) {
      form.setError('root.employee', {
        type: 'employee-no-slots',
        message: intl.formatMessage({ id: 'calendarEvent.employee.noSlotsAvailableError' }),
      });
      return;
    }

    if (form.formState.errors.root?.employee?.type === 'employee-no-slots') {
      form.clearErrors('root.employee');
    }
  }, [slotEmployees, employeeValue, slotOptions, form.formState.submitCount]);

  useEffect(() => {
    if (daySlotsState.loading) {
      return;
    }

    const isRelatedFieldsDirty = SLOT_FILTER_FIELDS.some((key) => form.formState.dirtyFields[key]);
    let slotToSet: AppointmentFormSlotItem | null = null;

    const optionByValueMap: Record<number, IOption> = {};
    for (let optionIndex = 0; optionIndex < slotOptions.length; optionIndex += 1) {
      const option = slotOptions[optionIndex];
      optionByValueMap[option.value] = option;
    }

    const possibleValues: AppointmentFormSlotItem[] = [];
    if (slotValue) {
      possibleValues.push(slotValue);
    }

    // eslint-disable-next-line no-underscore-dangle
    const defaultValue = form.control._defaultValues.slot;

    if (defaultValue && !isRelatedFieldsDirty) {
      possibleValues.push(defaultValue as AppointmentFormSlotItem);
    }

    const possibleValue = possibleValues.find((slotOption) => optionByValueMap[slotOption.value]);

    if (possibleValue) {
      slotToSet = possibleValue;
    }

    form.setValue('slot', slotToSet, { shouldValidate: true });
  }, [slotOptions]);

  useEffect(() => {
    dispatch(resetSwapSlots());
  }, [dateValue, servicesValue, employeeValue]);

  useEffect(
    () => () => {
      dispatch(resetDaySlots());
      dispatch(resetSwapSlots());
    },
    [],
  );

  return (
    <>
      {!!servicesValue.length && (
        <PreferredSlotSelectSection
          value={value}
          onChange={(updatedValue) => {
            onChange(updatedValue as AppointmentFormSlotItem | null);
            form.trigger('slot');
          }}
          options={slotOptions}
          loading={daySlotsState.loading}
          employeeId={employeeValue}
          swapEnabled={store.settings.swap}
          swapOptions={swapSlotOptions}
          onFetchSwapOptionsClick={fetchSwapOptions}
          swapOptionsLoading={swapSlotsState.loading}
          swapOptionsError={!!swapSlotsState.error}
        />
      )}
    </>
  );
});

// eslint-disable-next-line prefer-arrow-callback
const CreateAppointmentForm = React.memo(function CreateAppointmentForm(props: CreateCalendarEventFormProps<IAppointmentFormValues>) {
  const {
    defaultValues,
    onSubmit: propsOnSubmit,
    onCalendarEventTypeChange,
    calendarEventType,
    onEmployeesFetch,
    visible,
    appointmentTypeOptionItems,
  } = props;

  const intl = useIntl();

  const store = useAppSelector(selectCurrentStore);

  const form = useForm<IAppointmentFormValues>({
    resolver: yupResolver(appointmentFormSchema),
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = form.handleSubmit(async (values: IAppointmentFormValues) => {
    let duration: number | undefined;
    if (values.services.length > 0) {
      duration = values.services.reduce((acc, service) => acc + service.extra.duration, 0);
    }

    let title: Nullable<string> = null;
    if (values.title != null) {
      title = values.title;
    } else {
      title = [values.firstName, values.lastName].join(' ').trim();
    }

    let time = 0;
    let swap;
    if (values.slot && values.slot.value) {
      time = values.slot.value;
      if (values.slot.extra && values.slot.extra.isSwap) {
        swap = true;
      }
    }

    const params: ICreateCalendarEventQueryParams = {
      duration,
      date: values.date,
      time,
      swap,
      type: CalendarEventTypeEnum.APPOINTMENT,
      title,
      note: values.note || '',
      eventTypeData: {
        services: values.services.map((serviceOption) => serviceOption.value),
        suggestedEmployee: values.employee || undefined,
        customer: {
          name: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          email: values.email,
          gender: values.gender || undefined,
          locale: 'en',
        },
      },
    };

    await propsOnSubmit(params);
  });

  useEffect(() => {
    if (visible) {
      form.reset(defaultValues);
    }
  }, [visible]);

  if (!visible) {
    return <></>;
  }

  return (
    <FormProvider {...form}>
      <form>
        <Grid data-testid="create-appointment-window" container spacing={2}>
          <Grid item xs={8}>
            <Stack spacing={2}>
              <Paper>
                <DialogSection gutterBottom>
                  <ToggleButtonGroup
                    exclusive
                    spacing={1}
                    value={calendarEventType}
                    onChange={(event, value) => {
                      if (!value) return;

                      onCalendarEventTypeChange(value, form.getValues());
                    }}
                  >
                    {appointmentTypeOptionItems.map((option) => (
                      <ToggleButton
                        data-testid={`appointment-type-${option.value}-button`}
                        key={option.value}
                        value={option.value}
                        variant="rounded"
                        color="secondary"
                        disabled={option.extra?.disabled}
                      >
                        {intl.formatMessage({ id: option.label })}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </DialogSection>
                <CalendarEventFormServicesSection />
              </Paper>

              <Paper>
                <Stack direction="column" spacing={2}>
                  <DialogSection title={intl.formatMessage({ id: 'appointment.customer' })}>
                    <AppointmentFormCustomerSection size="default" schema={appointmentFormSchema} />
                  </DialogSection>
                </Stack>
              </Paper>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={2}>
              <Paper>
                <DialogSection title={intl.formatMessage({ id: 'appointment.specificEmployee' })}>
                  <Controller
                    control={form.control}
                    name="employee"
                    render={({ field, fieldState }) => (
                      <CalendarEventFormSuggestedEmployeeInput
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        error={fieldState.error}
                        onOptionsFetch={onEmployeesFetch}
                      />
                    )}
                  />
                </DialogSection>
              </Paper>

              <Paper>
                <DialogSection data-testid="calendar-event-form-date-picker" title={intl.formatMessage({ id: 'appointment.date' })}>
                  <Controller
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <CalendarEventFormDateInputWithSlots
                        value={field.value}
                        onChange={field.onChange}
                        timezone={store.timezone}
                      />
                    )}
                  />
                </DialogSection>

                <Controller
                  control={form.control}
                  name="slot"
                  render={({ field }) => <CalendarEventFormSlotInput value={field.value} onChange={field.onChange} />}
                />
              </Paper>

              <AsyncButton
                data-testid="form-create-appointment-button"
                className={styles.submitButton}
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                {intl.formatMessage({ id: 'calendar.createAppointment' })}
              </AsyncButton>

              {form.formState.isSubmitted && !(form.formState.isValid && !Object.keys(form.formState.errors).length) && (
                <FormHelperText error>{intl.formatMessage({ id: 'common.formHasErrors' })}</FormHelperText>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
});

// eslint-disable-next-line prefer-arrow-callback
const CreateBossAppointmentForm = React.memo(function CreateBossAppointmentForm(
  props: CreateCalendarEventFormProps<IBossAppointmentFormValues>,
) {
  const {
    defaultValues,
    onSubmit: propsOnSubmit,
    onCalendarEventTypeChange,
    calendarEventType,
    onEmployeesFetch,
    visible,
    appointmentTypeOptionItems,
  } = props;

  const intl = useIntl();

  const store = useAppSelector(selectCurrentStore);
  const form = useForm<IBossAppointmentFormValues>({
    resolver: yupResolver(bossAppointmentFormSchema),
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const bossModeDays = store.settings.bossMode ? store.settings.bossMode.days : 0;
  const availableDays = TimeHelper.getDaysFromTo(
    TimeHelper.toDayjs(new Date(), store.timezone),
    TimeHelper.addToDate(new Date(), store.timezone, bossModeDays),
  );

  const onSubmit = form.handleSubmit(async (values: IBossAppointmentFormValues) => {
    let duration: number | undefined;
    if (values.services.length > 0) {
      duration = values.services.reduce((acc, service) => acc + service.extra.duration, 0);
    }

    let title: Nullable<string> = null;
    if (values.title != null) {
      title = values.title;
    } else {
      title = [values.firstName, values.lastName].join(' ').trim();
    }

    const params: ICreateCalendarEventQueryParams = {
      duration,
      date: values.date,
      time: values.from as number,
      type: CalendarEventTypeEnum.APPOINTMENT,
      title,
      note: `${values.note || ''} (Boss Mode Buchung)`,
      eventTypeData: {
        services: values.services.map((serviceOption) => serviceOption.value),
        suggestedEmployee: values.employee || undefined,
        customer: {
          name: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          email: values.email,
          gender: values.gender || undefined,
          locale: 'en',
        },
      },
      skipSlots: true,
    };

    await propsOnSubmit(params);
  });

  useEffect(() => {
    if (visible) {
      form.reset(defaultValues);
    }
  }, [visible]);

  if (!visible) {
    return <></>;
  }

  return (
    <FormProvider {...form}>
      <form>
        <Grid data-testid="create-appointment-window" container spacing={2}>
          <Grid item xs={8}>
            <Stack spacing={2}>
              <Paper>
                <DialogSection gutterBottom>
                  <ToggleButtonGroup
                    exclusive
                    spacing={1}
                    value={calendarEventType}
                    onChange={(event, value) => {
                      if (!value) return;

                      onCalendarEventTypeChange(value, form.getValues());
                    }}
                  >
                    {appointmentTypeOptionItems.map((option) => (
                      <ToggleButton
                        data-testid={`appointment-type-${option.value}-button`}
                        key={option.value}
                        value={option.value}
                        variant="rounded"
                        color="secondary"
                        disabled={option.extra?.disabled}
                      >
                        {intl.formatMessage({ id: option.label })}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </DialogSection>

                <Box className={styles.warningOverwrite}>
                  <Typography fontWeight={500} fontSize={16}>
                    {intl.formatMessage({ id: 'appointment.overwriteWarning' })}
                  </Typography>
                </Box>

                <CalendarEventFormServicesSection allowedServices={store.settings.bossMode?.services || []} />
              </Paper>

              <Paper>
                <Stack direction="column" spacing={2}>
                  <DialogSection title={intl.formatMessage({ id: 'appointment.customer' })}>
                    <AppointmentFormCustomerSection size="default" schema={appointmentFormSchema} />
                  </DialogSection>
                </Stack>
              </Paper>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack spacing={2}>
              <Paper>
                <DialogSection title={intl.formatMessage({ id: 'appointment.specificEmployee' })}>
                  <Controller
                    control={form.control}
                    name="employee"
                    render={({ field, fieldState }) => (
                      <CalendarEventFormSuggestedEmployeeInput
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        error={fieldState.error}
                        onOptionsFetch={onEmployeesFetch}
                      />
                    )}
                  />
                </DialogSection>
              </Paper>

              <Paper>
                <DialogSection data-testid="calendar-event-form-date-picker" title={intl.formatMessage({ id: 'appointment.date' })}>
                  <Controller
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <DateInput
                        disableNotHighlighted
                        availableDays={availableDays}
                        value={field.value}
                        onChange={field.onChange}
                        timezone={store.timezone}
                      />
                    )}
                  />
                </DialogSection>

                <AppointmentFormTimeSection justFrom />
              </Paper>

              <AsyncButton
                data-testid="form-create-appointment-button"
                className={styles.submitButton}
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                {intl.formatMessage({ id: 'calendar.createAppointment' })}
              </AsyncButton>

              {form.formState.isSubmitted && !(form.formState.isValid && !Object.keys(form.formState.errors).length) && (
                <FormHelperText error>{intl.formatMessage({ id: 'common.formHasErrors' })}</FormHelperText>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
});

// eslint-disable-next-line prefer-arrow-callback
const CreateBreakForm = React.memo(function CreateBreakForm(props: CreateCalendarEventFormProps<IBreakFormValues>) {
  const {
    onSubmit: propsOnSubmit,
    onCalendarEventTypeChange,
    calendarEventType,
    defaultValues,
    onEmployeesFetch,
    visible,
    appointmentTypeOptionItems,
  } = props;

  const intl = useIntl();

  const store = useAppSelector(selectCurrentStore);

  const form = useForm<IBreakFormValues>({
    resolver: yupResolver(breakFormSchema),
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = form.handleSubmit(async (values: IBreakFormValues) => {
    let duration: number | undefined;
    let time = 0;
    if (values.from != null && values.to != null) {
      time = values.from;
      duration = values.to - values.from;
    }

    const params: ICreateCalendarEventQueryParams = {
      type: CalendarEventTypeEnum.BREAK,
      date: values.date,
      time,
      title: values.title || '',
      note: values.note || '',
      duration,
      eventTypeData: {
        suggestedEmployee: values.employee || undefined,
      },
    };

    await propsOnSubmit(params);
  });

  useEffect(() => {
    if (visible) {
      form.reset(defaultValues);
    }
  }, [visible]);

  if (!visible) {
    return <></>;
  }

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item flex={1} xs={8}>
            <Stack spacing={2}>
              <Paper>
                <DialogSection gutterBottom>
                  <ToggleButtonGroup
                    exclusive
                    spacing={1}
                    value={calendarEventType}
                    onChange={(event, value) => {
                      if (!value) return;

                      onCalendarEventTypeChange(value, form.getValues());
                    }}
                  >
                    {appointmentTypeOptionItems.map((option) => (
                      <ToggleButton
                        data-testid={`appointment-type-${option.value}-button`}
                        key={option.value}
                        value={option.value}
                        variant="rounded"
                        color="secondary"
                        disabled={option.extra?.disabled}
                      >
                        {intl.formatMessage({ id: option.label })}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </DialogSection>

                <AppointmentFormInformationSection />
              </Paper>
            </Stack>
          </Grid>

          <Grid item flex={0} xs={4}>
            <Stack spacing={2}>
              <Paper>
                <DialogSection title={intl.formatMessage({ id: 'appointment.specificEmployee' })}>
                  <Controller
                    control={form.control}
                    name="employee"
                    render={({ field, fieldState }) => (
                      <CalendarEventFormSuggestedEmployeeInput
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        error={fieldState.error}
                        onOptionsFetch={onEmployeesFetch}
                        required={isRequired(breakFormSchema, 'employee')}
                      />
                    )}
                  />
                </DialogSection>
              </Paper>

              <Paper>
                <DialogSection data-testid="calendar-event-form-date-picker" title={intl.formatMessage({ id: 'appointment.date' })}>
                  <Controller
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <DateInput
                        value={field.value}
                        onChange={field.onChange}
                        timezone={store.timezone}
                      />
                    )}
                  />
                </DialogSection>

                <AppointmentFormTimeSection />
              </Paper>

              <AsyncButton
                data-testid="form-create-appointment-button"
                className={styles.submitButton}
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                {intl.formatMessage({ id: 'calendar.createAppointment' })}
              </AsyncButton>

              {form.formState.isSubmitted && !(form.formState.isValid && !Object.keys(form.formState.errors).length) && (
                <FormHelperText error>{intl.formatMessage({ id: 'common.formHasErrors' })}</FormHelperText>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
});

// eslint-disable-next-line prefer-arrow-callback
const CreateBlockerForm = React.memo(function CreateBlockerForm(props: CreateCalendarEventFormProps<IBlockerFormValues>) {
  const {
    onSubmit: propsOnSubmit,
    onCalendarEventTypeChange,
    calendarEventType,
    defaultValues,
    onEmployeesFetch,
    visible,
    appointmentTypeOptionItems,
  } = props;

  const intl = useIntl();

  const store = useAppSelector(selectCurrentStore);

  const form = useForm<IBlockerFormValues>({
    resolver: yupResolver(blockerFormSchema),
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = form.handleSubmit(async (values: IBlockerFormValues) => {
    let duration: number | undefined;
    let time = 0;
    if (values.from != null && values.to != null) {
      time = values.from;
      duration = values.to - values.from;
    }

    const params: ICreateCalendarEventQueryParams = {
      type: CalendarEventTypeEnum.BLOCKER,
      date: values.date,
      time,
      title: values.title || '',
      note: values.note || '',
      duration,
      eventTypeData: {
        suggestedEmployee: values.employee || undefined,
      },
    };

    await propsOnSubmit(params);
  });

  useEffect(() => {
    if (visible) {
      form.reset(defaultValues);
    }
  }, [visible]);

  if (!visible) {
    return <></>;
  }

  return (
    <FormProvider {...form}>
      <form>
        <Grid container spacing={2}>
          <Grid item flex={1} xs={8}>
            <Stack spacing={2}>
              <Paper>
                <DialogSection gutterBottom>
                  <ToggleButtonGroup
                    exclusive
                    spacing={1}
                    value={calendarEventType}
                    onChange={(event, value) => {
                      if (!value) return;

                      onCalendarEventTypeChange(value, form.getValues());
                    }}
                  >
                    {appointmentTypeOptionItems.map((option) => (
                      <ToggleButton
                        data-testid={`appointment-type-${option.value}-button`}
                        key={option.value}
                        value={option.value}
                        variant="rounded"
                        color="secondary"
                        disabled={option.extra?.disabled}
                      >
                        {intl.formatMessage({ id: option.label })}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </DialogSection>

                <AppointmentFormInformationSection />
              </Paper>
            </Stack>
          </Grid>

          <Grid item flex={0} xs={4}>
            <Stack spacing={2}>
              <Paper>
                <DialogSection title={intl.formatMessage({ id: 'appointment.specificEmployee' })}>
                  <Controller
                    control={form.control}
                    name="employee"
                    render={({ field, fieldState }) => (
                      <CalendarEventFormSuggestedEmployeeInput
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        error={fieldState.error}
                        onOptionsFetch={onEmployeesFetch}
                        required={isRequired(blockerFormSchema, 'employee')}
                      />
                    )}
                  />
                </DialogSection>
              </Paper>

              <Paper>
                <DialogSection data-testid="calendar-event-form-date-picker" title={intl.formatMessage({ id: 'appointment.date' })}>
                  <Controller
                    control={form.control}
                    name="date"
                    render={({ field }) => (
                      <DateInput
                        value={field.value}
                        onChange={field.onChange}
                        timezone={store.timezone}
                      />
                    )}
                  />
                </DialogSection>

                <AppointmentFormTimeSection />
              </Paper>

              <AsyncButton
                data-testid="form-create-appointment-button"
                className={styles.submitButton}
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                {intl.formatMessage({ id: 'calendar.createAppointment' })}
              </AsyncButton>

              {form.formState.isSubmitted && !(form.formState.isValid && !Object.keys(form.formState.errors).length) && (
                <FormHelperText error>{intl.formatMessage({ id: 'common.formHasErrors' })}</FormHelperText>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
});

function CreateCalendarEventNoticeStack() {
  const intl = useIntl();

  const calendarSlotsState = useAppSelector((state) => state.slots.slots, shallowEqual);
  const createCalendarEventState = useAppSelector((state) => state.appointments.createCalendarEvent, shallowEqual);
  const daySlotsState = useAppSelector((state) => state.slots.dateSlots, shallowEqual);

  const [createCalendarEventErrorShown, setCreateCalendarEventErrorShown] = useState(false);
  const [slotsErrorShown, setSlotsErrorShown] = useState(false);

  const onErrorNoticeClose = useCallback(() => {
    setCreateCalendarEventErrorShown(false);
  }, []);

  useEffect(() => {
    setSlotsErrorShown(Boolean(calendarSlotsState.error || daySlotsState.error));
  }, [calendarSlotsState.error, daySlotsState.error]);

  useEffect(() => {
    setCreateCalendarEventErrorShown(Boolean(createCalendarEventState.error));
  }, [createCalendarEventState.error]);

  return (
    <NoticeStack>
      <Notice
        open={createCalendarEventErrorShown}
        onClose={onErrorNoticeClose}
        severity="error"
        title={intl.formatMessage({ id: 'common.createAppointmentError' })}
        description={intl.formatMessage({ id: 'common.createAppointmentErrorDescription' })}
      />
      <Notice
        open={slotsErrorShown}
        onClose={() => setSlotsErrorShown(false)}
        severity="error"
        autoHideDuration={60000}
        title={intl.formatMessage({ id: 'slot.slotsFetchErrorTitle' })}
      />
    </NoticeStack>
  );
}

function CalendarEventFormWrapper({ onSubmit, params, timezone, onEmployeesFetch, storeSettings }) {
  const intl = useIntl();
  const [calendarEventType, setCalendarEventType] = useState(params.type || CalendarEventTypeEnum.APPOINTMENT);
  const [sharedValues, setSharedValues] = useState<CreateCalendarEventSharedValues>({});

  const onCalendarEventTypeChange = useCallback(
    (
      value: CalendarEventTypeEnum,
      formValues: Partial<IAppointmentFormValues | IBossAppointmentFormValues | IBreakFormValues | IBlockerFormValues>,
    ) => {
      ReactGA.event({
        category: 'Appointment',
        action: 'Change type',
      });

      setCalendarEventType(value);
      setSharedValues({ ...sharedValues, ...formValues });
    },
    [sharedValues],
  );

  const appointmentTypeOptionItems = useMemo(() => {
    if (storeSettings.bossMode?.enabled) {
      return appointmentTypeOptions;
    }
    return appointmentTypeOptions.filter((dayOption) => dayOption.value !== CalendarEventTypeEnum.BOSS_APPOINTMENT);
  }, [storeSettings]);

  return (
    <>
      <CreateAppointmentForm
        defaultValues={getCreateAppointmentFormDefaultValues(params, timezone, sharedValues, intl)}
        onCalendarEventTypeChange={onCalendarEventTypeChange}
        calendarEventType={calendarEventType}
        onSubmit={onSubmit}
        onEmployeesFetch={onEmployeesFetch}
        visible={calendarEventType === CalendarEventTypeEnum.APPOINTMENT}
        appointmentTypeOptionItems={appointmentTypeOptionItems}
      />
      <CreateBossAppointmentForm
        defaultValues={getCreateBossAppointmentFormDefaultValues(params, timezone, sharedValues, intl, storeSettings)}
        onCalendarEventTypeChange={onCalendarEventTypeChange}
        calendarEventType={calendarEventType}
        onSubmit={onSubmit}
        onEmployeesFetch={onEmployeesFetch}
        visible={calendarEventType === CalendarEventTypeEnum.BOSS_APPOINTMENT}
        appointmentTypeOptionItems={appointmentTypeOptionItems}
      />
      <CreateBreakForm
        defaultValues={getCreateBreakFormDefaultValues(params, timezone, sharedValues, intl)}
        onCalendarEventTypeChange={onCalendarEventTypeChange}
        calendarEventType={calendarEventType}
        onSubmit={onSubmit}
        onEmployeesFetch={onEmployeesFetch}
        visible={calendarEventType === CalendarEventTypeEnum.BREAK}
        appointmentTypeOptionItems={appointmentTypeOptionItems}
      />
      <CreateBlockerForm
        defaultValues={getCreateBlockerFormDefaultValues(params, timezone, sharedValues)}
        onCalendarEventTypeChange={onCalendarEventTypeChange}
        calendarEventType={calendarEventType}
        onSubmit={onSubmit}
        onEmployeesFetch={onEmployeesFetch}
        visible={calendarEventType === CalendarEventTypeEnum.BLOCKER}
        appointmentTypeOptionItems={appointmentTypeOptionItems}
      />
    </>
  );
}

export function CreateCalendarEvent({ onClose, params }: Props) {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const store = useAppSelector(selectCurrentStore);

  const prevEmployeesFetchParams = useRef<{ date: string } | null>(null);
  const onEmployeesFetch = useCallback((date: string) => {
    const shouldFetch = !prevEmployeesFetchParams.current || prevEmployeesFetchParams.current.date !== date;
    if (shouldFetch) {
      const from = TimeHelper.toDayjs(date, store.timezone);
      dispatch(fetchEmployees({ from, timezone: store.timezone }));
      prevEmployeesFetchParams.current = { date };
    }
  }, []);

  const onSubmit = useCallback(
    async (createCalendarEventParams: ICreateCalendarEventQueryParams) => {
      if (!store) return;
      dispatch(createCalendarEvents(createCalendarEventParams));
      onClose();
    },
    [store],
  );

  useEffect(
    () => () => {
      dispatch(resetCreateCalendarEvent());
    },
    [],
  );

  if (!store) {
    return <></>;
  }

  return (
    <>
      <Dialog
        // eslint-disable-next-line
        open={true}
        fullScreen
      >
        <DialogHeader text={intl.formatMessage({ id: 'calendar.createAppointment' })} onClose={onClose} />
        <DialogContent className={styles.content}>
          <CalendarEventFormWrapper
            onSubmit={onSubmit}
            params={params}
            timezone={store.timezone}
            onEmployeesFetch={onEmployeesFetch}
            storeSettings={store?.settings}
          />
        </DialogContent>
      </Dialog>

      <CreateCalendarEventNoticeStack />
    </>
  );
}
