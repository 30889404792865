import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import {
  convertExternalAppointment,
  deleteCalendarEvents,
  fetchCalendarEvents,
  getCalendarEvent,
  syncBreaksToDiwa,
  searchCalendarEvents,
  updateCalendarEvents, createCalendarEvents,
} from './thunks';
import { Appointment, CalendarEventTypeEnum, Employee, ExternalAppointment, CalendarEventSearchItem } from '../../types';
import { mapBulkPayload } from '../../utils/socketIo';
import { getSplitServiceAppointments } from '../../utils';

interface InitialState {
  loading: boolean;
  deleting: boolean;
  searchFetched: boolean;
  searchHasNextPage: boolean;
  deletionError: any;
  error: any;
  singleAppointment: Appointment | null;
  data: Appointment[];
  searchData: CalendarEventSearchItem[];
  createCalendarEvent: {
    data: Appointment | null;
    loading: boolean;
    error: SerializedError | null;
  },
  updateCalendarEvent: {
    data: Appointment | null;
    loading: boolean;
    error: SerializedError | null;
  },
}

const initialState: InitialState = {
  loading: false,
  deleting: false,
  searchFetched: false,
  searchHasNextPage: true,
  deletionError: null,
  error: null,
  singleAppointment: null,
  data: [],
  searchData: [],
  createCalendarEvent: {
    data: null,
    loading: false,
    error: null,
  },
  updateCalendarEvent: {
    data: null,
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    addCalendarEvent: (state, action) => {
      const data = getSplitServiceAppointments([action.payload.data], action.payload.timezone);

      state.data = state.data.concat(data.map((calendarEvent) => convertExternalAppointment(calendarEvent, action.payload.timezone)));
    },
    updateCalendarEvent: (state, action) => {
      const updatedCalendarEvent = convertExternalAppointment(action.payload.data, action.payload.timezone);
      const calendarEventId = updatedCalendarEvent.id;
      const updatedData = state.data.filter((event) => event.id !== calendarEventId);

      const data = getSplitServiceAppointments([updatedCalendarEvent], action.payload.timezone);

      updatedData.push(...data);
      state.data = updatedData;

      if (state.searchData.length > 0 && updatedCalendarEvent.type === CalendarEventTypeEnum.APPOINTMENT) {
        const updatedSearchData = state.searchData.filter((event) => event.id !== calendarEventId);
        updatedSearchData.push(updatedCalendarEvent);
        state.searchData = updatedSearchData;
      }
    },
    deleteCalendarEvent: (state, action) => {
      const calendarEventId = action.payload.id;
      const updatedData = state.data.filter((event) => event.id !== calendarEventId);
      if (updatedData.length !== state.data.length) {
        state.data = updatedData;
      }
      if (state.searchData.length > 0) {
        const updatedSearchData = state.searchData.filter((event) => event.id !== calendarEventId);
        if (updatedSearchData.length !== state.searchData.length) {
          state.searchData = updatedSearchData;
        }
      }
    },
    syncCalendarEvents: (
      state,
      action: {
        payload: {
          data: {
            create: ExternalAppointment[];
            update: ExternalAppointment[];
            delete: Appointment[];
          };
          timezone: string;
        };
      },
    ) => {
      const data = {
        create: action.payload.data.create.map((item) => convertExternalAppointment(item, action.payload.timezone)),
        update: action.payload.data.update.map((item) => convertExternalAppointment(item, action.payload.timezone)),
        delete: action.payload.data.delete,
      };
      state.data = mapBulkPayload<Appointment>(state.data, data);
    },
    resetSingleAppointment(state) {
      state.singleAppointment = null;
    },
    resetCreateCalendarEvent(state) {
      state.createCalendarEvent = initialState.createCalendarEvent;
    },
    resetUpdateCalendarEvent(state) {
      state.updateCalendarEvent = initialState.updateCalendarEvent;
    },
    resetSearch(state) {
      state.searchFetched = false;
      state.searchHasNextPage = true;
      state.searchData = [];
    },
    updateSearchDataEmployees(state, action: PayloadAction<{ employees: Employee[] }>) {
      const employeesByIdMap = action.payload.employees.reduce<Record<string, Employee>>((acc, employee) => {
        acc[employee.id] = employee;
        return acc;
      }, {});

      const updatedSearchData = [...state.searchData];

      for (let searchItemIndex = 0; searchItemIndex < updatedSearchData.length; searchItemIndex += 1) {
        const searchItem = updatedSearchData[searchItemIndex];
        if (searchItem.pinnedEmployee && !searchItem.employee) {
          const employee = employeesByIdMap[searchItem.pinnedEmployee];
          if (employee) {
            updatedSearchData[searchItemIndex] = {
              ...searchItem,
              employee: {
                id: employee.id,
                name: employee.name,
                firstName: employee.firstName,
                lastName: employee.lastName,
              },
            };
          }
        }
      }

      state.searchData = updatedSearchData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCalendarEvents.pending, (state) => {
      state.loading = true;
      state.error = null;
      // state.data = []; //todo
    });
    builder.addCase(fetchCalendarEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    });

    builder.addCase(createCalendarEvents.pending, (state) => {
      state.createCalendarEvent.data = null;
      state.createCalendarEvent.loading = true;
      state.createCalendarEvent.error = null;
    });
    builder.addCase(createCalendarEvents.fulfilled, (state, action) => {
      state.createCalendarEvent.data = action.payload;
      state.createCalendarEvent.loading = false;
    });
    builder.addCase(createCalendarEvents.rejected, (state, action) => {
      state.createCalendarEvent.loading = false;
      state.createCalendarEvent.error = action.error;
    });

    builder.addCase(updateCalendarEvents.pending, (state) => {
      state.updateCalendarEvent.data = null;
      state.updateCalendarEvent.loading = true;
      state.updateCalendarEvent.error = null;

      state.error = null;
    });
    builder.addCase(updateCalendarEvents.fulfilled, (state, action) => {
      state.updateCalendarEvent.data = action.payload;
      state.updateCalendarEvent.loading = false;

      state.searchFetched = false;
      state.error = null;

      if (state.searchData.length) {
        const searchItemByIdMap = state.searchData.reduce((acc, appointment) => {
          acc[appointment.id] = appointment;
          return acc;
        }, {});

        if (searchItemByIdMap[action.payload.id]) {
          searchItemByIdMap[action.payload.id] = action.payload;
        }

        state.searchData = Object.values(searchItemByIdMap);
      }
    });
    builder.addCase(updateCalendarEvents.rejected, (state, action) => {
      state.updateCalendarEvent.loading = false;
      state.updateCalendarEvent.error = action.error;
    });

    builder.addCase(deleteCalendarEvents.pending, (state) => {
      state.deleting = true;
      state.deletionError = null;
      // state.data = []; //todo
    });
    builder.addCase(deleteCalendarEvents.fulfilled, (state, action) => {
      state.deleting = false;
      if (action.payload?.error) {
        state.deletionError = action.payload?.error.message || 'deletion error';
      } else {
        state.deletionError = null;
      }
      // state.data = action.payload; //todo remove locally
    });
    builder.addCase(deleteCalendarEvents.rejected, (state, payload) => {
      state.deleting = false;
      state.deletionError = payload?.error;
    });

    builder.addCase(syncBreaksToDiwa.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(syncBreaksToDiwa.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(syncBreaksToDiwa.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(searchCalendarEvents.pending, (state) => {
      state.loading = true;
      state.searchFetched = false;
      state.error = null;
    });
    builder.addCase(searchCalendarEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.searchFetched = true;
      state.error = null;
      state.searchHasNextPage = action.payload.length >= action.meta.arg.itemsPerPage;
      const searchResults = action.payload.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      if (action.meta.arg.page === 1) {
        state.searchData = action.payload;
      } else {
        state.searchData = state.searchData.filter((event) => !searchResults[event.id]).concat(action.payload);
      }
    });
    builder.addCase(searchCalendarEvents.rejected, (state, action) => {
      state.loading = false;
      state.searchFetched = true;
      state.searchHasNextPage = false;
      state.error = action.error;
    });

    builder.addCase(getCalendarEvent.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCalendarEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.singleAppointment = action.payload;
    });
    builder.addCase(getCalendarEvent.rejected, (state, action) => {
      state.loading = false;
      state.singleAppointment = null;
      state.error = action.error;
    });

    builder.addCase(fetchCalendarEvents.rejected, (state, payload) => {
      state.loading = false;
      state.error = payload?.error;
    });
  },
});

export const {
  addCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,
  syncCalendarEvents,
  resetSingleAppointment,
  resetSearch,
  updateSearchDataEmployees,
  resetCreateCalendarEvent,
  resetUpdateCalendarEvent,
} = slice.actions;
export default slice.reducer;
