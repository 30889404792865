import { createAsyncThunk } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import CLIENT, { apiClient } from '../../utils/CLIENT';
import { TimeHelper } from '../../utils';
import { EMPLOYEES_GET_BY_IDS_ERROR, EMPLOYEES_GET_ERROR } from '../types/employees';
import { Employee } from '../../types';

export const fetchEmployees = createAsyncThunk('employees/get', async (payload: { from: Dayjs; to?: Dayjs; timezone: string }) => {
  const dates = [payload.from.format('YYYY-MM-DD')];
  if (payload.to) {
    dates.push(payload.to.format('YYYY-MM-DD'));
  }

  const response = await apiClient.get('/lt-booking/employees', {
    params: {
      dates: dates.join(','),
      withShifts: true,
    },
  });

  if (!response || !response.data) {
    throw new Error('Undefined error');
  }

  return response.data;
});

export const getShiftsByDate = createAsyncThunk(
  'employees/getShiftsByDate',
  async (payload: { date: Dayjs, storeId: string }) => {
    const dateStr = TimeHelper.toStandardFormat(payload.date);
    const response = await CLIENT({
      url: `/employees/shifts-by-date?date=${dateStr}&storeId=${payload.storeId}`,
      method: 'GET',
    });

    if (response.data.error) {
      throw new Error(EMPLOYEES_GET_ERROR);
    }
    return response.data;
  },
);

export const getEmployeesByIds = createAsyncThunk(
  'employees/getByIds',
  async (employeeIds: string[]) => {
    const responses = await Promise.all(
      employeeIds.map((employeeId) => (
        CLIENT({
          url: `/employees/${employeeId}`,
          method: 'GET',
        })
      )),
    );

    const employees: Employee[] = [];

    for (let responseIndex = 0; responseIndex < responses.length; responseIndex += 1) {
      const response = responses[responseIndex];
      if (response.data.error) {
        throw new Error(EMPLOYEES_GET_BY_IDS_ERROR);
      }

      employees.push(response.data);
    }

    return responses.map((response) => response.data);
  },
);
