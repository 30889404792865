import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Employee, Shift } from '../../types';
import { fetchEmployees, getEmployeesByIds, getShiftsByDate } from './thunks';
import { TimeHelper } from '../../utils';

interface InitialState {
  loading: boolean;
  error: any;
  employees: Employee[];
  shifts: Record<string, Shift[]>;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  employees: [],
  shifts: {},
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    updateShifts: (
      state,
      action: PayloadAction<{
        shifts: { create: Shift[], update: Shift[], delete: Shift[] },
        date: string,
      }>,
    ) => {
      const { shifts: payloadShifts, date } = action.payload;

      const existingShifts = state.shifts[date] || [];

      const shiftsByEmployeeId = Object.entries(payloadShifts).reduce<Record<string, Record<string, Shift>>>((acc, [operation, items]) => {
        items.forEach((shift) => {
          acc[operation][shift.employeeId] = shift;
        });

        return acc;
      }, { create: {}, update: {}, delete: {} });

      const updatedShifts: Shift[] = [];
      existingShifts.forEach((item) => {
        if (shiftsByEmployeeId.update[item.employeeId]) {
          updatedShifts.push({ ...item, ...shiftsByEmployeeId.update[item.employeeId] });
          return;
        }
        if (shiftsByEmployeeId.delete[item.employeeId]) {
          return;
        }
        updatedShifts.push(item);
      });

      state.shifts[date] = updatedShifts.concat(payloadShifts.create);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEmployees.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchEmployees.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

      const employeesByIdMap = (state.employees || []).reduce((acc, employee) => {
        acc[employee.id] = employee;
        return acc;
      }, {});

      for (let employeeIndex = 0; employeeIndex < action.payload.length; employeeIndex += 1) {
        const employee = action.payload[employeeIndex];
        employeesByIdMap[employee.id] = {
          ...employeesByIdMap[employee.id],
          ...employee,
        };
      }

      state.employees = Object.values(employeesByIdMap);

      const existingShifts = Object.entries(state.shifts).reduce((acc, [date, shifts]) => {
        acc[date] = shifts.map((shift) => shift.id);
        return acc;
      }, {});
      state.shifts = action.payload.reduce((acc, employee) => {
        employee.shift.forEach((shift) => {
          const shiftDate = TimeHelper.toStandardFormat(TimeHelper.toDayjs(shift.start, action.meta.arg.timezone));
          if (!acc[shiftDate]) {
            acc[shiftDate] = [];
          }
          if (!existingShifts[shiftDate] || !existingShifts[shiftDate].includes(shift.id)) {
            acc[shiftDate].push(shift);
          }
        });
        return acc;
      }, state.shifts);
    });
    builder.addCase(fetchEmployees.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error; // todo check it
    });
    builder.addCase(getShiftsByDate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getShiftsByDate.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      const { date } = action.meta.arg;
      const dateStr = TimeHelper.toStandardFormat(date);
      const payloadShiftIds = action.payload.map((shift) => shift.id);
      state.shifts[dateStr] = (state.shifts[dateStr] || []).filter((shift) => !payloadShiftIds.includes(shift.id)).concat(action.payload);
    });
    builder.addCase(getShiftsByDate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(getEmployeesByIds.fulfilled, (state, action) => {
      const employeesByIdMap = state.employees.reduce((acc, employee) => {
        acc[employee.id] = employee;
        return acc;
      }, {});

      for (let employeeIndex = 0; employeeIndex < action.payload.length; employeeIndex += 1) {
        const employee = action.payload[employeeIndex];
        employeesByIdMap[employee.id] = {
          ...employeesByIdMap[employee.id],
          ...employee,
        };
      }

      state.employees = Object.values(employeesByIdMap);
    });
  },
});

export const { updateShifts } = slice.actions;
export default slice.reducer;
