import React, { useCallback, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import dayjs from 'dayjs';

import {
  CalendarCompact as AppointmentIcon,
  TaskRhombusIcon as TaskIcon,
  EditAppointment as EditAppointmentIcon,
  BreakTime as BreakIcon,
  BlockerIcon,
  WalkinIcon,
} from '@linetweet/linetweet-ui';

import { CalendarEventTypeEnum, Service, SearchCalendarEvent } from '../../../types';
import { parseEmployeeNameWithEmoji } from '../../../utils';
import { GetAppointmentCustomer, GetAppointmentServices, getFullNameWithPrefix, ServiceIcon } from '../../commons';

import styles from '../index.module.scss';

export type SearchItemProps = {
  appointmentData: SearchCalendarEvent;
  allServices: Service[];
  timezone: string;
  onClick: (params: { searchCalendarEvent: SearchCalendarEvent; isPastItem: boolean }) => void;
};

export function SearchItem({ appointmentData, allServices, timezone, onClick }: SearchItemProps) {
  const { calendarEvent, isFirstInDay, isLastInDay, day, dateTime } = appointmentData;
  const intl = useIntl();
  const formattedDay = day.format('DD.MM.YYYY');

  const employeeName = useMemo(() => {
    const { employee } = calendarEvent;
    if (!employee) {
      return null;
    }
    const { firstName, lastName } = employee;
    const { fullName } = parseEmployeeNameWithEmoji(`${firstName} ${lastName}`);

    return fullName;
  }, [calendarEvent.employee]);

  const services: Service[] = GetAppointmentServices(calendarEvent, allServices);
  const customer = GetAppointmentCustomer(calendarEvent);
  const startTime = day.set('minutes', calendarEvent.time).format('HH:mm');
  const endTime = day.set('minutes', calendarEvent.time + calendarEvent.duration).format('HH:mm');

  const isClickable = calendarEvent.type && [CalendarEventTypeEnum.APPOINTMENT, CalendarEventTypeEnum.TASK].includes(calendarEvent.type);

  const title = useMemo(() => {
    if (!customer) {
      return calendarEvent.title;
    }

    const prefix = customer.gender ? `${intl.formatMessage({ id: `common.gender.${customer.gender}` })}.` : '';

    return getFullNameWithPrefix(customer, prefix) || calendarEvent.title;
  }, [customer, calendarEvent.title]);

  const onClickHandler = useCallback(() => {
    const currentTime = dayjs.tz(Date.now(), timezone);
    const isPastItem = dateTime < currentTime.valueOf();
    onClick({ searchCalendarEvent: appointmentData, isPastItem });
  }, [calendarEvent, dateTime, timezone]);

  return (
    <>
      {isFirstInDay && <Typography className={styles.date}>{formattedDay}</Typography>}
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        className={clsx(styles.card, {
          [styles.clickable]: isClickable,
          [styles.isFirstInDay]: isFirstInDay,
          [styles.isLastInDay]: isLastInDay,
        })}
        key={`grouped-appointments-${formattedDay}-${calendarEvent.id}`}
        onClick={isClickable ? () => onClickHandler() : undefined}
      >
        {calendarEvent.type === CalendarEventTypeEnum.APPOINTMENT && <AppointmentIcon className={styles.appointmentIcon} />}
        {calendarEvent.type === CalendarEventTypeEnum.TASK && <TaskIcon className={styles.taskIcon} />}
        {calendarEvent.type === CalendarEventTypeEnum.WALKIN && <WalkinIcon className={styles.walkinIcon} />}
        {calendarEvent.type === CalendarEventTypeEnum.BREAK && <BreakIcon className={styles.breakIcon} />}
        {calendarEvent.type === CalendarEventTypeEnum.BLOCKER && <BlockerIcon className={styles.blockerIcon} />}
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" className={styles.textContainer}>
          <Typography className={styles.name}>{title}</Typography>
          <Typography className={styles.interval}>
            {day.format('dddd, MMM D')} <span>&#183;</span> {startTime} - {endTime}
          </Typography>
          {employeeName && (
            <Typography className={styles.employee}>
              {intl.formatMessage({ id: 'calendarOverview.employees' })}: {employeeName}
            </Typography>
          )}
          {calendarEvent.note && (
            <Typography className={styles.note}>
              {intl.formatMessage({ id: 'appointment.notes' })}: {calendarEvent.note}
            </Typography>
          )}
          {customer && (
            <>
              {customer.phoneNumber && (
                <Typography className={styles.note}>
                  {intl.formatMessage({ id: 'appointment.phoneNumber' })}: {customer.phoneNumber}
                </Typography>
              )}
              {customer.email && (
                <Typography className={styles.note}>
                  {intl.formatMessage({ id: 'appointment.email' })}: {customer.email}
                </Typography>
              )}
            </>
          )}
        </Stack>
        <Stack direction="row" className={styles.serviceList}>
          <div className={styles.services}>
            {services.map((service) => (
              <div key={service.id} className={styles.serviceWrapper}>
                <ServiceIcon icon={service.icon} />
              </div>
            ))}
          </div>
          {isClickable && <EditAppointmentIcon className={styles.editIcon} />}
        </Stack>
      </Stack>
    </>
  );
}
