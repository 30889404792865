import {
  Appointment,
  CalendarEventTypeEnum,
  Service,
  Slot,
} from 'types';

import { IOption, Nullable } from '@linetweet/linetweet-ui';
import { GetAppointmentCustomer, GetAppointmentServices } from 'features/commons';
import { TimeHelper } from '../../utils';
import { AppointmentFormSlotItem, CalendarEventCreateParams } from './types';

export function createSlotOptions(params: {
  timezone: string;
  slots: Slot[];
  employeeId?: Nullable<string>;
  defaultSlot?: AppointmentFormSlotItem;
  previousEmployeeId?: Nullable<string>;
  pinnedEmployee?: Nullable<string>;
}): IOption<number>[] {
  const currentDate = TimeHelper.toDayjs(Date.now(), params.timezone);
  const currentDateString = currentDate.format('YYYY-MM-DD');
  const currentTimeMinutes = TimeHelper.getMinutesFromHHmmTimeString(currentDate.format('HH:mm'));

  const slotSet = new Set<string>();
  const slotOptions: IOption<number>[] = [];

  for (let slotIndex = 0; slotIndex < params.slots.length; slotIndex += 1) {
    const slot = params.slots[slotIndex];
    const employeeIdFits = !params.employeeId || slot.employeeId === params.employeeId;

    if (employeeIdFits) {
      for (let timeSlotIndex = 0; timeSlotIndex < slot.timeSlots.length; timeSlotIndex += 1) {
        const timeSlot = slot.timeSlots[timeSlotIndex];
        if (!timeSlot.isSplitService || !params.employeeId) {
          const value = TimeHelper.getMinutesFromHHmmTimeString(timeSlot.time);
          const slotFits = slot.date === currentDateString ? value >= currentTimeMinutes : true;

          if (slotFits && !slotSet.has(timeSlot.time)) {
            slotSet.add(timeSlot.time);
            slotOptions.push({
              label: timeSlot.time,
              value,
            });
          }
        }
      }
    }
  }

  if (
    params.defaultSlot &&
    (!!params.previousEmployeeId || !params.employeeId || params.pinnedEmployee === params.employeeId)
  ) {
    if (!slotOptions.find((option) => option.value === params.defaultSlot?.value)) {
      slotOptions.push(params.defaultSlot);
    }
  }

  return slotOptions.sort((optionA, optionB) => optionA.value - optionB.value);
}

export function getDateRange(timezone: string, leadingDays: number): string[] {
  const startOfRange = TimeHelper.toDayjs(new Date(), timezone);
  const days: string[] = [];
  for (let dayOffset = 0; dayOffset < leadingDays; dayOffset += 1) {
    days.push(TimeHelper.toStandardFormat(startOfRange.add(dayOffset, 'days')));
  }
  return days;
}

export const calendarEventToCalendarCreateValues = (
  calendarEvent: Appointment,
  allServices: Service[],
  employeeId: string | undefined,
  timezone: string,
): CalendarEventCreateParams => {
  const customer = GetAppointmentCustomer(calendarEvent);
  const services: Service[] = GetAppointmentServices(calendarEvent, allServices);
  return {
    type: calendarEvent.type as unknown as CalendarEventTypeEnum,
    date: TimeHelper.getDateStringTz(new Date(), timezone),
    note: calendarEvent.note || null,
    gender: customer?.gender || null,
    services,
    duration: calendarEvent.duration || null,
    employeeId: employeeId || null,
    title: calendarEvent.title || null,
    lastName: customer?.lastName || '',
    firstName: customer?.name || '',
    email: customer?.email || '',
    phoneNumber: customer?.phoneNumber || '',
    recurring: null,
    from: calendarEvent.time,
    to: calendarEvent.time + (calendarEvent.duration || 0),
  };
};
