import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { EventWrapperProps } from 'react-big-calendar';

import { CalendarEventTypeEnum } from '../../../types';
import { CalendarEvent } from '../types';
import { TimeHelper } from '../../../utils';
import { useAppSelector } from '../../../store/hooks';

import styles from './EventWrapperComponent.module.scss';
import { selectCurrentStore } from '../../../store/store/selectors';

export function EventWrapper(props: EventWrapperProps & { event: CalendarEvent }) {
  const {
    style,
    className,
    event,
    accessors,
    selected,
    getters,
    // @ts-ignore
    components,
    onClick,
  } = props;
  const store = useAppSelector(selectCurrentStore);
  const title = accessors.title!(event);
  const end = accessors.end!(event);
  const start = accessors.start!(event);
  const userProps = getters.eventProp!(event, start, end, selected);

  const Event = components.event;

  const minutes = useMemo(() => {
    if (!start || !end) {
      return 0;
    }
    return (end.valueOf() - start.valueOf()) / 60000;
  }, [start, end]);

  const pastEvent = useMemo(() => end.valueOf() - TimeHelper.getNowDate(store.timezone).valueOf() <= 0, [end]);

  const calcHeight = (height?: string | number) => {
    if (event.type === CalendarEventTypeEnum.WORKING_HOURS) {
      return `${height}%`;
    }
    return `calc(${height}% - 2px)`;
  };

  const calcMargin = () => {
    if (event.type === CalendarEventTypeEnum.WORKING_HOURS) {
      return '0px';
    }
    return '2px';
  };

  const eventStyle = useMemo(
    () => ({
      top: style ? `${style.top}%` : 0,
      height: style ? calcHeight(style.height) : 0,
      minHeight: minutes <= 15 ? '16px' : '27px',
      marginTop: calcMargin(),
    }),
    [minutes, style],
  );

  return (
    <Box
      style={eventStyle}
      className={clsx(styles.eventWrapper, className, userProps.className, 'calendar-event', {
        [styles.inactive]: event.type !== CalendarEventTypeEnum.WORKING_HOURS && pastEvent,
      })}
      onClick={onClick}
    >
      <Box className={clsx(styles.eventContent, 'rbc-event-content')}>
        {Event ? <Event event={event} title={title} /> : title}
      </Box>
    </Box>
  );
}
