import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { shallowEqual } from 'react-redux';

import { EventsOverview } from './components';
import { OverviewHeader } from './subcomponents';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DayOverviewType, DaySummary } from '../../types';
import { isArrayEqual, TimeHelper } from '../../utils';
import { getEventStats } from '../../store/eventStats/thunks';
import { convertDaySummaryToCalendarDataType, getCalendarGridStartEndDates } from '../commons/services/calendarOverviewService';
import { selectCurrentStore, selectStoreServices } from '../../store/store/selectors';

import styles from './CalendarOverview.module.scss';

const ADDED_MONTHS = 2;

type Props = {
  date: dayjs.Dayjs;
  onClose: () => void;
};

export function CalendarOverview({ date, onClose }: Props) {
  const dispatch = useAppDispatch();
  const containerElement = useRef(null);

  const store = useAppSelector(selectCurrentStore);
  const eventStatsState = useAppSelector((state) => state.eventStats);
  const allServices = useAppSelector(selectStoreServices, shallowEqual);

  const currentDate = TimeHelper.toDayjs(Date.now(), store.timezone).startOf('day');
  const [prevPageDate, setPrevPageDate] = useState(date.clone().subtract(1, 'month'));
  const [nextPageDate, setNextPageDate] = useState(date.clone().add(1, 'month'));
  const [scrollDate, setScrollDate] = useState(date);
  const [isLoading, setIsLoading] = useState({ prev: false, next: false });
  const [renderData, setRenderData] = useState<Record<string, DayOverviewType>>({});
  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);

  const fetchPage = useCallback(
    async (fetchDate: Dayjs, addMonths: number) => {
      const rangeDates = getCalendarGridStartEndDates(fetchDate, addMonths);
      const { startOfPeriod, endOfPeriod } = rangeDates;
      if (eventStatsState.queryHaveResponse[`${startOfPeriod.valueOf()}-${endOfPeriod.valueOf()}`]) {
        return rangeDates;
      }
      dispatch(
        getEventStats({
          from: startOfPeriod,
          to: endOfPeriod,
          storeId: store.id,
          enterpriseId: store.enterpriseId,
        }),
      );
      return rangeDates;
    },
    [eventStatsState],
  );

  useEffect(() => {
    fetchPage(prevPageDate, ADDED_MONTHS);
    setInitialFetchCompleted(true);
  }, []);

  const handleScroll = useCallback(
    async (isNext: boolean) => {
      if (eventStatsState.loading) {
        return;
      }
      let fetchDate;
      let updatedScrollDate;

      if (isNext) {
        if (isLoading.next) {
          return;
        }
        fetchDate = nextPageDate.add(1, 'month');
        const rangeDates = await fetchPage(fetchDate, 0);
        updatedScrollDate = rangeDates.startOfPeriod;
        setIsLoading({ ...isLoading, next: true });
        setNextPageDate(fetchDate);
      } else {
        if (isLoading.prev) {
          return;
        }
        fetchDate = prevPageDate.subtract(1, 'month');
        const rangeDates = await fetchPage(fetchDate, 0);
        updatedScrollDate = rangeDates.endOfPeriod;
        setIsLoading({ ...isLoading, prev: true });
        setPrevPageDate(fetchDate);
      }

      setScrollDate(updatedScrollDate);
    },
    [nextPageDate, prevPageDate, eventStatsState, isLoading],
  );

  useEffect(() => {
    const updatedRenderData = {};
    const existingData = Object.entries(eventStatsState.data).reduce((acc, [dayStr, dayStats]) => {
      acc[dayStr] = dayStats;
      return acc;
    }, {} as Record<string, DaySummary>);

    Object.entries(existingData).forEach(([dayStr, dayData]) => {
      updatedRenderData[dayStr] = convertDaySummaryToCalendarDataType(dayData, allServices);
    });

    if (!isArrayEqual(renderData, updatedRenderData)) {
      setRenderData({ ...renderData, ...updatedRenderData });
      setTimeout(() => {
        setIsLoading({ prev: false, next: false });
      }, 500);
    }
  }, [eventStatsState.data]);

  const isDateMonthLoaded = useMemo(() => {
    const rangeDates = getCalendarGridStartEndDates(date.clone().subtract(1, 'month'), ADDED_MONTHS);
    const { startOfPeriod, endOfPeriod } = rangeDates;

    if (!eventStatsState.queryHaveResponse[`${startOfPeriod.valueOf()}-${endOfPeriod.valueOf()}`]) {
      return false;
    }

    return initialFetchCompleted;
  }, [date, eventStatsState.queryHaveResponse, eventStatsState.data, initialFetchCompleted]);

  return (
    <div className={styles.calendar_overview_wrapper} ref={containerElement}>
      <OverviewHeader date={date} onBackClick={onClose} />
      {isDateMonthLoaded && (
        <div className={styles.calendar_container}>
          <EventsOverview
            onScroll={handleScroll}
            data={renderData}
            currentDate={currentDate}
            isLoading={isLoading}
            scrollDate={scrollDate}
          />
        </div>
      )}
    </div>
  );
}
