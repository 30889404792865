import { DateLocalizer, DateLocalizerSpec } from 'react-big-calendar';
import * as dates from 'date-arithmetic';

// import dayjs plugins
// Note that the timezone plugin is not imported here
// this plugin can be optionally loaded by the user
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import { Dayjs } from 'dayjs';
import { TimeHelper } from './timeHelper';

function fixUnit(unit?: string): string | undefined {
  let datePart = unit ? unit.toLowerCase() : unit;
  if (datePart === 'FullYear') {
    datePart = 'year';
  } else if (!datePart) {
    datePart = undefined;
  }
  return datePart;
}

export const DayjsLocalizer = (dayjsLib: any, timezone: string) => {
  // load dayjs plugins
  dayjsLib.extend(isBetween);
  dayjsLib.extend(isSameOrAfter);
  dayjsLib.extend(isSameOrBefore);
  dayjsLib.extend(localeData);
  dayjsLib.extend(localizedFormat);
  dayjsLib.extend(minMax);
  dayjsLib.extend(utc);

  dayjsLib.tz.setDefault(timezone);

  const locale = (dj, c) => (c ? dj.locale(c) : dj);

  // if the timezone plugin is loaded,
  // then use the timezone aware version
  const dayjs = dayjsLib.tz ? dayjsLib.tz : dayjsLib;

  const cachedDays = {};

  const cachedOffsets = {};

  const cacheInRange = {};

  const cachedFormatted = {};

  const cachedTimeZone = timezone;

  function cacheWrap(date: Date | null, modifier: string = 'dayjs') {
    let timeStamp;
    let dateType = 'date';
    if (!date) {
      timeStamp = dayjs(date, timezone).valueOf();
      dateType = 'undefined';
    } else if (typeof date.getTime === 'undefined' && date) {
      timeStamp = date.valueOf();
      dateType = 'dayjs';
    } else {
      timeStamp = date.getTime();
      dateType = 'date';
    }
    if (typeof cachedDays[timeStamp] === 'undefined') {
      cachedDays[timeStamp] = {};
    }

    if (typeof cachedDays[timeStamp][modifier] === 'undefined') {
      const dayjsObj = dateType === 'dayjs' ? date : dayjs(date, timezone);
      if (modifier.endsWith('dayjs')) {
        cachedDays[timeStamp][modifier] = dayjsObj;
      } else if (modifier.startsWith('startOf_')) {
        cachedDays[timeStamp][modifier] = dayjsObj.startOf(modifier.replace('startOf_', ''));
      } else if (modifier.startsWith('endOf_')) {
        cachedDays[timeStamp][modifier] = dayjsObj.endOf(modifier.replace('endOf_', ''));
      } else if (modifier === 'timestamp') {
        cachedDays[timeStamp][modifier] = dayjsObj.valueOf();
      }
    }

    return cachedDays[timeStamp][modifier];
  }

  function formattedCacheWrap(
    formatter: DateLocalizer,
    date: string | number | Date,
    format: string,
    culture?: string,
  ): string | undefined {
    if (!formatter) {
      return;
    }
    let timeStamp;
    if (typeof date === 'number') {
      timeStamp = date;
    } else if (typeof date === 'string') {
      timeStamp = date;
    } else {
      timeStamp = date.getTime();
    }

    if (typeof cachedFormatted[timeStamp] === 'undefined') {
      cachedFormatted[timeStamp] = {};
    }

    if (typeof cachedFormatted[timeStamp][format] === 'undefined') {
      cachedFormatted[timeStamp][format] = formatter.format(date, format, culture);
    }

    return cachedFormatted[timeStamp][format];
  }

  const weekRangeFormat = ({ start, end }, culture, local) => {
    const from = formattedCacheWrap(local, start, 'MMMM DD', culture);
    const to = formattedCacheWrap(local, end, local.eq(start, end, 'month') ? 'DD' : 'MMMM DD', culture);
    return `${from} - ${to}`;
  };

  const dateRangeFormat = ({ start, end }, culture, local) => {
    const from = formattedCacheWrap(local, start, 'L', culture);
    const to = formattedCacheWrap(local, end, 'L', culture);
    return `${from} - ${to}`;
  };

  const timeRangeFormat = ({ start, end }, culture, local) => {
    const from = formattedCacheWrap(local, start, 'LT', culture);
    const to = formattedCacheWrap(local, end, 'LT', culture);
    return `${from} - ${to}`;
  };

  const timeRangeStartFormat = ({ start }, culture, local) => {
    const from = formattedCacheWrap(local, start, 'LT', culture);
    return `${from} - `;
  };

  const timeRangeEndFormat = ({ end }, culture, local) => {
    const to = formattedCacheWrap(local, end, 'LT', culture);
    return `- ${to}`;
  };

  const formats = {
    dateFormat: 'DD',
    dayFormat: 'DD ddd',
    weekdayFormat: 'ddd',

    selectRangeFormat: timeRangeFormat,
    eventTimeRangeFormat: timeRangeFormat,
    eventTimeRangeStartFormat: timeRangeStartFormat,
    eventTimeRangeEndFormat: timeRangeEndFormat,

    timeGutterFormat: 'LT',

    monthHeaderFormat: 'MMMM YYYY',
    dayHeaderFormat: 'dddd MMM DD',
    dayRangeHeaderFormat: weekRangeFormat,
    agendaHeaderFormat: dateRangeFormat,

    agendaDateFormat: 'ddd MMM DD',
    agendaTimeFormat: 'LT',
    agendaTimeRangeFormat: timeRangeFormat,
  };

  function getTimezoneOffset(date: Date) {
    // ensures this gets cast to timezone
    return cacheWrap(date).toDate().getTimezoneOffset();
  }

  function getDstOffset(start: Date, end: Date) {
    return 0;
    // // convert to dayjs, in case
    // const st = cacheWrap(start);
    // const ed = cacheWrap(end);
    // const cacheOffsetKey = `${st.valueOf()}-${ed.valueOf()}`;
    // if (cachedOffsets[cacheOffsetKey]) {
    //   return cachedOffsets[cacheOffsetKey];
    // }
    // // if not using the dayjs timezone plugin
    // if (!dayjs.tz) {
    //   const res = st.toDate().getTimezoneOffset() - ed.toDate().getTimezoneOffset();
    //   cachedOffsets[cacheOffsetKey] = res;
    //   return res;
    // }
    // /**
    //  * If a default timezone has been applied, then
    //  * use this to get the proper timezone offset, otherwise default
    //  * the timezone to the browser local
    //  */
    // const tzName = st.tz().$x.$timezone ?? dayjsLib.tz.guess();
    // // invert offsets to be inline with moment.js
    // const startOffset = -dayjs.tz(+st, tzName).utcOffset();
    // const endOffset = -dayjs.tz(+ed, tzName).utcOffset();
    // const res = startOffset - endOffset;
    // cachedOffsets[cacheOffsetKey] = res;

    // return res;
  }

  function getDayStartDstOffset(start) {
    return 60 * TimeHelper.hoursOffsetBetweenDifferentTimezones(dayjsLib.tz.guess(), cachedTimeZone, start);

    // const dayStart = cacheWrap(start, 'startOf_day');
    // return getDstOffset(dayStart, start);
  }

  /** * BEGIN localized date arithmetic methods with dayjs ** */
  function defineComparators(a: Date, b: Date, unit?: string) {
    const datePart = fixUnit(unit);
    const dtA = cacheWrap(a, datePart ? `startOf_${datePart}` : undefined);
    const dtB = cacheWrap(b, datePart ? `startOf_${datePart}` : undefined);
    return [dtA, dtB, datePart];
  }

  function startOf(date: Date | null = null, unit?: string) {
    const datePart = fixUnit(unit);
    if (datePart) {
      return cacheWrap(date, `startOf_${datePart}`).toDate();
    }
    return cacheWrap(date).toDate();
  }

  function endOf(date: Date | null = null, unit: string) {
    const datePart = fixUnit(unit);
    if (datePart) {
      return cacheWrap(date, `endOf_${datePart}`).toDate();
    }
    return cacheWrap(date).toDate();
  }

  // dayjs comparison operations *always* convert both sides to dayjs objects
  // prior to running the comparisons
  function eq(a: Date, b: Date, unit?: string) {
    const [dtA, dtB, datePart] = defineComparators(a, b, unit);

    const startOfDateValue = dtA.valueOf();
    const endOfDateValue = cacheWrap(dtA, datePart ? `endOf_${datePart}` : undefined).valueOf();
    const otherDateValue = dtB.valueOf();
    return startOfDateValue <= otherDateValue && otherDateValue <= endOfDateValue;
  }

  function neq(a: Date, b: Date, unit?: string) {
    return !eq(a, b, unit);
  }

  function gt(a: Date, b: Date, unit?: string) {
    const [dtA, dtB, _datePart] = defineComparators(a, b, unit);

    const startOfDateValue = dtA.valueOf();
    const otherDateValue = dtB.valueOf();

    return otherDateValue < startOfDateValue;
  }

  function lt(a: Date, b: Date, unit?: string) {
    const [dtA, dtB, _datePart] = defineComparators(a, b, unit);

    const endOfDateValue = dtA.valueOf();
    const otherDateValue = dtB.valueOf();

    return endOfDateValue < otherDateValue;
  }

  function gte(a: Date, b: Date, unit?: string) {
    const [dtA, dtB, datePart] = defineComparators(a, b, unit);
    return isSameOrAfterCheck(dtA, dtB, datePart);
  }

  function lte(a: Date, b: Date, unit?: string) {
    const [dtA, dtB, datePart] = defineComparators(a, b, unit);
    return isSameOrBeforeCheck(dtA, dtB, datePart);
  }

  function isBetweenCheck(date: Date, otherDateA: Date, otherDateB: Date, datePart: string | undefined, i: string = '()') {
    const dAi = i[0] === '(';
    const dBi = i[1] === ')';

    const startOfDateValue = cacheWrap(date, `startOf_${datePart}`).valueOf();
    const endOfDateValue = cacheWrap(date, `endOf_${datePart}`).valueOf();
    const otherDateAValue = cacheWrap(otherDateA).valueOf();
    const otherDateBValue = cacheWrap(otherDateB).valueOf();

    let afterABeforeB = false;
    const afterA = dAi ? otherDateAValue < startOfDateValue : otherDateAValue <= endOfDateValue;
    const beforeB = dBi ? otherDateBValue > endOfDateValue : otherDateBValue >= startOfDateValue;
    if (afterA && beforeB) {
      afterABeforeB = true;
    }

    let beforeAAfterB = false;
    const beforeA = dAi ? otherDateAValue > endOfDateValue : otherDateAValue >= startOfDateValue;
    const afterB = dBi ? otherDateBValue < startOfDateValue : otherDateBValue <= endOfDateValue;

    if (beforeA && afterB) {
      beforeAAfterB = true;
    }
    return afterABeforeB || beforeAAfterB;
  }

  function inRange(day: Date, minDate: Date, maxDate: Date, unit = 'day') {
    const cacheInRangeKey = `${day.getTime()}-${minDate.getTime()}-${maxDate.getTime()}-${unit}`;
    if (typeof cacheInRange[cacheInRangeKey] !== 'undefined') {
      return cacheInRange[cacheInRangeKey];
    }
    const datePart = fixUnit(unit);
    const startOfDate = cacheWrap(day, `startOf_${datePart}`);
    const startOfDateValue = cacheWrap(startOfDate, 'timestamp');
    const endOfDate = cacheWrap(day, `endOf_${datePart}`);
    const endOfDateValue = cacheWrap(endOfDate, 'timestamp');
    const otherDateAValue = cacheWrap(minDate, 'timestamp');
    const otherDateBValue = cacheWrap(maxDate, 'timestamp');

    let afterABeforeB = false;
    const afterA = otherDateAValue <= endOfDateValue;
    const beforeB = otherDateBValue >= startOfDateValue;
    if (afterA && beforeB) {
      afterABeforeB = true;
    }

    let beforeAAfterB = false;
    const beforeA = otherDateAValue >= startOfDateValue;
    const afterB = otherDateBValue <= endOfDateValue;

    if (beforeA && afterB) {
      beforeAAfterB = true;
    }
    const res = afterABeforeB || beforeAAfterB;
    cacheInRange[cacheInRangeKey] = res;
    return res;
  }

  function min(dateA: Date, dateB: Date) {
    const dtA = cacheWrap(dateA);
    const dtAValue = dtA.valueOf();
    const dtB = cacheWrap(dateB);
    const dtBValue = dtB.valueOf();

    const minDtValue = Math.min(dtAValue, dtBValue);
    const minDt = minDtValue === dtAValue ? dtA : dtB;
    return minDt.toDate();
  }

  function max(dateA: Date, dateB: Date) {
    const dtA = cacheWrap(dateA);
    const dtAValue = dtA.valueOf();
    const dtB = cacheWrap(dateB);
    const dtBValue = dtB.valueOf();

    const maxDtValue = Math.max(dtAValue, dtBValue);
    const maxDt = maxDtValue === dtAValue ? dtA : dtB;
    return maxDt.toDate();
  }

  function merge(date: Date, time: Date) {
    if (!date && !time) return null;

    const tm = cacheWrap(time).format('H:m');
    const tmSplit = tm.split(':');
    const mergedMinutes = parseInt(tmSplit[0], 10) * 60 + parseInt(tmSplit[1], 10);
    return cacheWrap(date, 'startOf_day').add(mergedMinutes, 'minutes').toDate();
  }

  function add(date: Date, adder: number, unit?: string) {
    const datePart = fixUnit(unit);
    return cacheWrap(date).add(adder, datePart).toDate();
  }

  function range(start: Date, end: Date, unit: string = 'day') {
    const datePart = fixUnit(unit);
    // because the add method will put these in tz, we have to start that way
    let current = cacheWrap(start).toDate();
    const days: Date[] = [];

    while (lte(current, end)) {
      days.push(current);
      current = add(current, 1, datePart);
    }

    return days;
  }

  function ceil(date: Date, unit: string) {
    const datePart = fixUnit(unit);
    const floor = startOf(date, datePart);

    return eq(floor, date) ? floor : add(floor, 1, datePart);
  }

  function diff(a: Date, b: Date, unit: string = 'day') {
    const datePart = fixUnit(unit);
    // don't use 'defineComparators' here, as we don't want to mutate the values
    const dtA = cacheWrap(a);
    const dtB = cacheWrap(b);
    return dtB.diff(dtA, datePart);
  }

  function minutes(date: Date) {
    const dt = cacheWrap(date);
    return dt.minutes();
  }

  function firstOfWeek(culture: any) {
    const data = culture ? dayjsLib.localeData(culture) : dayjsLib.localeData();
    return data ? data.firstDayOfWeek() : 0;
  }

  function firstVisibleDay(date: Date): Date {
    return cacheWrap(date, 'startOf_month').startOf('week').toDate();
  }

  function lastVisibleDay(date: Date): Date {
    return cacheWrap(date, 'endOf_month').endOf('week').toDate();
  }

  function visibleDays(date: Date): Date[] {
    let current = firstVisibleDay(date);
    const last = lastVisibleDay(date);
    const days: Date[] = [];

    while (lte(current, last)) {
      days.push(current);
      current = add(current, 1, 'd');
    }

    return days;
  }

  /** * END localized date arithmetic methods with dayjs ** */

  /**
   * Moved from TimeSlots.js, this method overrides the method of the same name
   * in the localizer.js, using dayjs to construct the js Date
   * @param {Date} dt - date to start with
   * @param {Number} minutesFromMidnight
   * @param {Number} offset
   * @returns {Date}
   */
  function getSlotDate(dt: Date, minutesFromMidnight: number, offset: number): Date {
    return cacheWrap(dt, 'startOf_day')
      .minute(minutesFromMidnight + offset)
      .toDate();
  }

  // dayjs will automatically handle DST differences in it's calculations
  function getTotalMin(start: Date, end: Date) {
    return diff(start, end, 'minutes');
  }

  function getMinutesFromMidnight(start: Date) {
    const dayStart = cacheWrap(start, 'startOf_day');
    const day = cacheWrap(start);
    return day.diff(dayStart, 'minutes') + getDayStartDstOffset(start);
  }

  // These two are used by DateSlotMetrics
  function continuesPrior(start: Date, first: Date) {
    return isBefore(start, first, 'day');
  }

  function continuesAfter(_start: Date, end: Date, last: Date) {
    return isSameOrAfterCheck(end, last, 'minutes');
  }

  // These two are used by eventLevels
  function sortEvents({
    evtA,
    evtB,
  }: {
    evtA: {
      start?: Date;
      end?: Date;
      allDay?: boolean;
    };
    evtB: {
      start?: Date;
      end?: Date;
      allDay?: boolean;
    };
  }): boolean {
    const { start: aStart, end: aEnd, allDay: aAllDay } = evtA;
    const { start: bStart, end: bEnd, allDay: bAllDay } = evtB;
    const startSort = +startOf(aStart, 'day') - +startOf(bStart, 'day');

    const durA = diff(aStart as Date, ceil(aEnd as Date, 'day'), 'day');

    const durB = diff(bStart as Date, ceil(bEnd as Date, 'day'), 'day');

    // @ts-ignore
    return startSort || Math.max(durB, 1) - Math.max(durA, 1) || !!bAllDay - !!aAllDay || +aStart - +bStart || +aEnd - +bEnd;
  }

  function inEventRange({
    event,
    range,
  }: {
    event: {
      start?: Date;
      end?: Date;
    };
    range: {
      start: Date;
      end: Date;
    };
  }): boolean {
    const { start, end } = event;
    const { start: rangeStart, end: rangeEnd } = range;
    const startOfDay = cacheWrap(start as Date, 'startOf_day');

    const eEnd = cacheWrap(end as Date);
    const rStart = cacheWrap(rangeStart as Date);
    const rEnd = cacheWrap(rangeEnd as Date);

    const startsBeforeEnd = isSameOrBeforeCheck(startOfDay, rEnd, 'day');
    // when the event is zero duration we need to handle a bit differently
    const sameMin = !isSame(startOfDay, eEnd, 'minutes');
    const endsAfterStart = sameMin ? isAfter(eEnd, rStart, 'minutes') : isSameOrAfterCheck(eEnd, rStart, 'minutes');

    return startsBeforeEnd && endsAfterStart;
  }

  function isSame(date: Date, otherDate: Date, units: string) {
    const startOfDateValue = cacheWrap(cacheWrap(date, `startOf_${units}`), 'timestamp');
    const endOfDateValue = cacheWrap(cacheWrap(date, `endOf_${units}`), 'timestamp');
    const otherDateValue = cacheWrap(otherDate, 'timestamp');

    return startOfDateValue <= otherDateValue && otherDateValue <= endOfDateValue;
  }

  function isAfter(date: Date, otherDate: Date, units: string) {
    const startOfDateValue = cacheWrap(cacheWrap(date, `startOf_${units}`), 'timestamp');
    const otherDateValue = cacheWrap(otherDate, 'timestamp');

    return otherDateValue < startOfDateValue;
  }

  function isBefore(date: Date, otherDate: Date, units: string) {
    const endOfDateValue = cacheWrap(cacheWrap(date, `endOf_${units}`), 'timestamp');
    const otherDateValue = cacheWrap(otherDate, 'timestamp');

    return endOfDateValue < otherDateValue;
  }

  function isSameOrBeforeCheck(date: Date, otherDate: Date, units: string) {
    const startOfDateValue = cacheWrap(cacheWrap(date, `startOf_${units}`), 'timestamp');
    const endOfDateValue = cacheWrap(cacheWrap(date, `endOf_${units}`), 'timestamp');
    const otherDateValue = cacheWrap(otherDate, 'timestamp');

    return (startOfDateValue <= otherDateValue && otherDateValue <= endOfDateValue) || otherDateValue > endOfDateValue;
  }

  function isSameOrAfterCheck(date: Date, otherDate: Date, units: string) {
    const startOfDateValue = cacheWrap(cacheWrap(date, `startOf_${units}`), 'timestamp');
    const endOfDateValue = cacheWrap(cacheWrap(date, `endOf_${units}`), 'timestamp');
    const otherDateValue = cacheWrap(otherDate, 'timestamp');

    return (startOfDateValue <= otherDateValue && otherDateValue <= endOfDateValue) || startOfDateValue > otherDateValue;
  }

  function isSameDate(date1: Date, date2: Date) {
    return isSame(date1, date2, 'day');
  }

  function isJustDate(date: Date) {
    return dates.hours(date) === 0 && dates.minutes(date) === 0 && dates.seconds(date) === 0 && dates.milliseconds(date) === 0;
  }

  function startAndEndAreDateOnly(start, end) {
    return isJustDate(start) && isJustDate(end);
  }

  /**
   * This method, called once in the localizer constructor, is used by eventLevels
   * 'eventSegments()' to assist in determining the 'span' of the event in the display,
   * specifically when using a timezone that is greater than the browser native timezone.
   * @returns number
   */
  function browserTZOffset() {
    /**
     * Date.prototype.getTimezoneOffset horrifically flips the positive/negative from
     * what you see in it is string, so we have to jump through some hoops to get a value
     * we can actually compare.
     */
    const dt = new Date();
    const neg = /-/.test(dt.toString()) ? '-' : '';
    const dtOffset = dt.getTimezoneOffset();
    const comparator = Number(`${neg}${Math.abs(dtOffset)}`);
    // dayjs correctly provides positive/negative offset, as expected
    const mtOffset = dayjs().utcOffset();
    return mtOffset > comparator ? 1 : 0;
  }

  const spec: DateLocalizerSpec & {
    browserTZOffset: () => number;
  } = {
    formats,

    firstOfWeek,
    firstVisibleDay,
    lastVisibleDay,
    visibleDays,
    startOfWeek: 0,
    startAndEndAreDateOnly,
    format(value, format, culture) {
      return locale(dayjs(value, timezone), culture).format(format);
    },
    segmentOffset: browserTZOffset(),
    lt,
    lte,
    gt,
    gte,
    eq,
    neq,
    merge,
    inRange,
    startOf,
    endOf,
    range,
    add,
    diff,
    ceil,
    min,
    max,
    minutes,

    getSlotDate,
    getTimezoneOffset,
    getDstOffset,
    getTotalMin,
    getMinutesFromMidnight,
    continuesPrior,
    continuesAfter,
    sortEvents: sortEvents as (args: any) => any,
    inEventRange: inEventRange as (args: any) => boolean,
    isSameDate,
    browserTZOffset,
  };

  return new DateLocalizer(spec);
};
