import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../index';
import { Store } from '../../types';

export const selectCurrentStore = (state: RootState): Store => state.store.data!;

export const selectStoreServices = createSelector((state: RootState) => state.store, (storeState) => {
  if (!storeState.data || storeState.loading) {
    return [];
  }
  if (typeof storeState.data === 'undefined') {
    return [];
  }
  if (!storeState.data.services) {
    return [];
  }
  const settingsByServiceId = storeState.data.serviceSettings ? storeState.data.serviceSettings.reduce((acc, settings) => {
    acc[settings.service] = settings;
    return acc;
  }, {}) : {};
  return storeState.data.services.map((service) => {
    const settings = settingsByServiceId[service.id];
    if (!settings) {
      return service;
    }
    const overrideSettings = Object.entries(settings).reduce((acc, [fieldName, fieldValue]) => {
      if (fieldName !== 'id' && typeof service[fieldName] !== 'undefined') {
        acc[fieldName] = fieldValue;
      }
      return acc;
    }, {});
    return {
      ...service,
      ...overrideSettings,
    };
  });
});
