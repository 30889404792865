import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { deDE, frFR, enUS } from 'locales';
import { useAppSelector } from 'store/hooks';
import { Nullable } from '@linetweet/linetweet-ui';
import { LocaleKeyEnum } from '../../types';

const localeMap: Record<LocaleKeyEnum, any> = {
  [LocaleKeyEnum.deDE]: deDE,
  [LocaleKeyEnum.frFR]: frFR,
  [LocaleKeyEnum.enUS]: enUS,
};

export interface ILocaleSelectContext {
  locale: LocaleKeyEnum;
  setLocale: (locale: LocaleKeyEnum) => void;
  resetLocale: () => void;
}

export const LocaleSelectContext = createContext<ILocaleSelectContext | null>(null);

export const LOCAL_STORAGE_LOCALE_KEY = 'language';

export function LocaleProvider({ children }: PropsWithChildren) {
  const browserLocale = window.navigator.language as LocaleKeyEnum;
  const possibleLocales = Object.values(LocaleKeyEnum);
  const defaultLocale = 'de-DE' as LocaleKeyEnum;

  const storeState = useAppSelector((state) => state.store);

  const storeLocale = useMemo(() => {
    if (storeState.data?.defaultLanguage) {
      return storeState.data?.defaultLanguage;
    }
    return defaultLocale;
  }, [storeState]);

  const [locale, setLocale] = useState<LocaleKeyEnum>(() => {
    const storageLocale = localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) as Nullable<LocaleKeyEnum>;
    if (storageLocale && possibleLocales.includes(storageLocale)) {
      return storageLocale;
    }

    if (browserLocale && possibleLocales.includes(browserLocale)) {
      return browserLocale;
    }

    return storeLocale;
  });

  useEffect(() => {
    const storageLocale = localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) as Nullable<LocaleKeyEnum>;
    if (storageLocale && possibleLocales.includes(storageLocale)) {
      return;
    }
    if (browserLocale && possibleLocales.includes(browserLocale)) {
      return;
    }

    setLocale(storeLocale);
  }, [storeLocale, browserLocale, possibleLocales]);

  const changeLocale = useCallback((updatedLocale: LocaleKeyEnum) => {
    localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, updatedLocale);
    setLocale(updatedLocale);
  }, []);

  const resetLocale = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_LOCALE_KEY);
    if (browserLocale && possibleLocales.includes(browserLocale)) {
      setLocale(browserLocale);
      return;
    }
    setLocale(defaultLocale);
  }, [browserLocale, possibleLocales]);

  const localeMessages = useMemo(() => {
    const selectedLocaleMessages = localeMap[locale];
    const defaultLocaleMessages = localeMap[defaultLocale];
    Object.keys(defaultLocaleMessages).forEach((key) => {
      if (typeof selectedLocaleMessages[key] === 'undefined') {
        selectedLocaleMessages[key] = defaultLocaleMessages[key];
      }
    });

    if (selectedLocaleMessages) {
      return selectedLocaleMessages;
    }

    return {};
  }, [locale]);

  const localeSelect: ILocaleSelectContext = useMemo(
    () => ({
      locale,
      setLocale: changeLocale,
      resetLocale,
    }),
    [locale, changeLocale, resetLocale],
  );

  return (
    <LocaleSelectContext.Provider value={localeSelect}>
      <IntlProvider
        messages={localeMessages}
        locale="en-US"
        defaultLocale="en-US"
        defaultRichTextElements={{
          // eslint-disable-next-line react/no-unstable-nested-components
          red: (chunks) => (
            <span
              style={{
                color: 'red',
              }}
            >
              {chunks}
            </span>
          ),
        }}
      >
        {children}
      </IntlProvider>
    </LocaleSelectContext.Provider>
  );
}
