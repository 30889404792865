import { Employee } from '../../../types';
import { parseEmployeeNameWithEmoji } from '../../../utils';

const employeeToOption = (employee: Employee) => {
  const { id, firstName, lastName } = employee;
  const nameValues = parseEmployeeNameWithEmoji(`${firstName} ${lastName}`);
  return {
    id,
    iconLabel: nameValues.name,
    label: nameValues.fullName,
  };
};

export const createEmployeeOptions = (params: {
  employeeId: string | null;
  storeEmployees: Employee[] | undefined;
  availableEmployees: Employee[];
}) => {
  const options = params.availableEmployees.map(employeeToOption);

  if (params.employeeId && !options.find((option) => option.id === params.employeeId)) {
    if (params.storeEmployees) {
      const employee = params.storeEmployees.find((storeEmployee) => storeEmployee.id === params.employeeId);
      if (employee) {
        options.push(employeeToOption(employee));
      }
    }
  }

  return options.sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));
};
