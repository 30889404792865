import { Appointment, AppointmentEvent } from "types";
import { TimeHelper } from "./timeHelper";

export const getSplitServiceAppointments = (calendarEvents: Appointment[], timezone: string) => {
  const splitServiceAppointments: any[] = [];

  for (let calendarEventIndex = 0; calendarEventIndex < calendarEvents.length; calendarEventIndex++) {
    const calendarEvent = calendarEvents[calendarEventIndex];
    const eventTypeData = calendarEvent.eventTypeData as AppointmentEvent;

    if (eventTypeData?.split) {
      for (let splitIndex = 0; splitIndex < eventTypeData.split.length; splitIndex += 1) {
        const dateTime = TimeHelper.toDayjs(eventTypeData.split[splitIndex].dateTime, timezone);
        splitServiceAppointments.push({
          ...calendarEvent,
          dateTime: eventTypeData.split[splitIndex].dateTime,
          time: TimeHelper.convertToMinutesFromDayStart(dateTime),
          duration: eventTypeData.split[splitIndex].duration,
          pinnedEmployee: calendarEvent.pinnedEmployee && eventTypeData.split[splitIndex].pinnedEmployee,
          eventTypeData: {
            ...calendarEvent.eventTypeData,
            services: eventTypeData.split[splitIndex].services,
          },
          overrides: {
            time: (calendarEvent as any).dateTime ?
              TimeHelper.timestampToDateAndTimeTz((calendarEvent as any).dateTime, timezone).time :
              calendarEvent.time,
            pinnedEmployee: calendarEvent.pinnedEmployee,
            duration: calendarEvent.duration,
            services: eventTypeData.services,
          }
        });
      }
    } else {
      splitServiceAppointments.push(calendarEvent);
    }
  }

  return splitServiceAppointments;
}
