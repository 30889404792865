import React, { useEffect, useMemo } from 'react';
import { LoadingWrapper } from '@linetweet/linetweet-ui';
import { Outlet } from 'react-router-dom';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../store/hooks';
import { connect } from '../store/global/slice';
import { RootState } from '../store';

export function AuthWrapper() {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state: RootState) => state.user, shallowEqual);
  const storeState = useAppSelector((state: RootState) => state.store, shallowEqual);

  useEffect(() => {
    dispatch(connect());
  }, []);

  const loading = useMemo(() => {
    const isUserFetched = userState.currentUser.data || userState.currentUser.error;
    const isUserLoading = userState.currentUser.loading || !isUserFetched;

    const isStoreFetched = storeState.data || storeState.error;
    const isStoreLoading = storeState.loading || !isStoreFetched;

    return isUserLoading || isStoreLoading;
  }, [userState, storeState]);

  if (userState.currentUser.error) {
    return <div>Access forbidden</div>;
  }

  if (storeState.error) {
    return <div>Cannot get store</div>;
  }

  return (
    <LoadingWrapper loading={loading}>
      <Outlet />
    </LoadingWrapper>
  );
}
