export default {
  'common.formHasErrors': 'Please check the form for missing required fields',
  'common.from': 'from',
  'common.to': 'to',
  'common.today': 'Today',
  'common.more': 'More',
  'common.editEntity': 'Edit',
  'common.abort': 'Cancel',
  'common.delete': 'Delete',
  'common.language': 'Select',
  'common.defaultMenuTitle': 'Appointments',
  'common.switchNdl': 'Switch NDL',
  'common.signOut': 'Sign out',
  'common.defaultStoreSettings': 'Store settings',
  'common.save': 'Save',
  'common.confirm': 'Confirm',
  'common.error': 'Error',
  'common.defaultError': 'Something went wrong',
  'common.defaultErrorDescription': 'Clear cache and reload',
  'common.createAppointmentError': 'An error occurred while creating the event',
  'common.createAppointmentErrorDescription': 'The event was not created. Please check your internet connection',
  'common.deleteAppointmentError': 'An error occurred while trying to delete the event',
  'common.deleteAppointmentErrorDescription': 'The event has not been deleted. Please check your internet connection',
  'common.appVersion': 'Version',
  'common.minutesShort': '{minutes} min',
  'common.hour': 'Hour',
  'common.minutes': 'Minutes',
  'common.gender.none': '-',
  'common.gender.male': 'Mr',
  'common.gender.female': 'Ms',
  'common.search': 'Search',
  'common.epepSyncInProcess': 'Import of {source} data started',
  'common.epepSyncDelay': 'Waiting for {source} data: {delay}s',
  'filter.title': 'Focus on',
  'appointment.copyToNew': 'Copy information to new event',
  'appointment.information': 'Information',
  'appointment.recurringEvent': 'Recurring event',
  'appointment.recurringEventPlaceholder': 'Non-recurring',
  'appointment.lastName': 'Last name',
  'appointment.firstName': 'First name',
  'appointment.email': 'E-mail',
  'appointment.phoneNumber': 'Phone number',
  'appointment.notes': 'Notes',
  'appointment.title': 'Title',
  'appointment.preferredTimes': 'Recommended times',
  'appointment.availableTimes': 'Available times',
  'appointment.showPreferred': 'Show recommended only',
  'appointment.showAll': 'Show all',
  'appointment.overwriteWarning': 'Are you sure? This will overwrite everything.',
  'appointment.swapTimes': 'More Options',
  'appointment.swapTimesDescription':
    'A computationally intensive check of the solution space can lead to further appointment options. This enables appointments for specific employees by exchanging appointments between employees.',
  'appointment.swapTimesEmployeeNotSelected': 'To review more options, select an employee.',
  'appointment.swapTimesFetch': 'Check more options',
  'appointment.swapTimesEmpty': 'No further options available',
  'appointment.appointment': 'Appointment',
  'appointment.bossAppointment': 'Boss mode',
  'appointment.walkin': 'Walk-in',
  'appointment.task': 'Task',
  'appointment.break': 'Break',
  'appointment.blocker': 'Blocker',
  'appointment.customer': 'Customer',
  'appointment.specificEmployee': 'Specific employee',
  'appointment.date': 'Date',
  'appointment.service': 'Service',
  'appointment.selectAllServices': 'Select all',
  'appointment.unselectAllServices': 'Unselect all',
  'appointment.anyAvailableEmployee': 'Any available employee',
  'language:dialogTitle': 'Select language',
  'language.german': 'Deutsch',
  'language.english': 'English',
  'language.french': 'Français',
  'service.visionTest': 'Eye Exam',
  'service.advisory': 'Service',
  'service.sunglasses': 'Sunglasses',
  'service.driverVisionTest': 'Eye exam for driving licence',
  'service.pickup': 'Pick-up',
  'service.service': 'Service',
  'service.workshop': 'Workshop',
  'service.serviceWorkshop': 'Workshop service',
  'service.eyeCheckUp': 'Eye-Check-Up',
  'service.augen': 'Eye-Check-Up',
  'service.KLAdjustment': 'Contact lens fitting',
  'service.KLFollowUpInspection': 'KL follow-up inspection',
  'service.serviceHA': 'Service HA',
  'service.firstConsultation': 'First consultation appointment',
  'service.shortHearingTest': 'Short hearing test',
  'service.followUpAppointment': 'Follow-up appointment',
  'service.submissionCompletion': 'Collection / completion',
  'service.postControl': 'Follow-up check',
  'service.adultPostControl': 'Advanced follow-up check',
  'service.earProtection': 'Hearing protection',
  'service.orderOptimization': 'Order optimization',
  'service.other': 'Other',
  'service.BO': 'BO',
  'service.KL': 'CL',
  'service.HA': 'HA',
  'service.followUpFitting': 'Follow-up fitting',
  'service.videoConsultation': 'Video consultation',
  'service.consultation': 'Consultation',
  'menu.operations': 'Operations',
  'menu.performance': 'Performance',
  'menu.appointments': 'Appointments',
  'menu.confirmEpepResyncTitle': 'Confirm {source} synchronization',
  'menu.confirmPlanoResyncTitle': 'Are you sure you waited 60 minutes after making a change in Plano?',
  'menu.confirmEpepResyncContent': 'Please confirm the synchronization of {source}',
  'menu.confirmPlanoResyncContent': '<red> Please only confirm the synchronization of Plano if you have waited 60 minutes. A new synchronization is only possible after 60 minutes! </red>',
  'menu.confirmBreaksResyncTitle': 'Confirm to sync breaks',
  'menu.confirmBreaksResyncContent': 'Please confirm the synchronization of the breaks',
  'menu.epepSync': 'Load {source} data',
  'menu.epepSyncDescription': 'Click here to load recently changed data from {source} into LTB.',
  'menu.PlanoSyncDescription':
    '<red> Attention! Plano needs up to 60 minutes to provide the Terminplaner with changed shift data! Click here to load changed data from Plano and DiWa-Skills into the Terminplaner. </red>',
  'menu.breaksSync': 'Sync breaks',
  'menu.breaksSyncDescription': 'Click here if you would like to overwrite the break planning in DiWa with the LTB break values.',
  'calendarOverview.overflowItems': 'Conf lict',
  'calendarOverview.employees': 'Employees',
  'calendarOverview.noEvents': 'No events',
  'resources.EyeTestRooms': 'Eye Exam Room',
  'resources.ContactLenses': 'CL Room',
  'resources.Horgerate': 'HA Room',
  'calendar.employee': 'Employee',
  'calendar.employeeWorkingHours': 'Work: {from} to {to}',
  'calendar.appointments': 'Appointments',
  'calendar.blockers': 'Blockers',
  'calendar.breaks': 'Breaks',
  'calendar.tasks': 'Tasks',
  'calendar.slots': 'Slots',
  'calendar.walkin': 'Walkin',
  'calendar.deleteAppoinctmentDialog': 'Do you really want to delete? This action cannot be undone.',
  'calendar.deleteAppointmentDialogTitle': 'Are you sure?',
  'calendar.walkinTitle': 'Reserved for walk-ins',
  'calendar.reserveTitle': 'Reserved',
  'calendar.createAppointment': 'Add new appointment',
  'calendar.appointmentIsDeleting': 'Event will be deleted',
  'calendar.appointmentIsDeletingDescription': 'Please wait for the update',
  'calendar.submit': 'Apply',
  'calendar.storeNotWorking': 'The strore is closed in selected date.',
  'calendar.overflow': 'Overflow',
  'search.title': 'Search',
  'search.noResults': 'No Results',
  'search.noName': 'No name',
  'calendarEvent.employee.noSlotsAvailableError': 'There are no free slots available for selected employee',
  'userAgent.notCompatibleTitle': 'Not compatible',
  'userAgent.notCompatibleDescription': 'Your current browser is not compatible with LineTweet Booking (LTB). Please use Edge',
  'userAgent.notCompatibleTip': 'Use Edge',
  'slot.slotsFetchErrorTitle': 'An error happened when trying to get slots, can you please try again',
  'slots.recalculationInProgress': 'Slot recalculation is happening for day {dateString}',
};
