import { createSlice } from '@reduxjs/toolkit';
import { getUser, getUserSettings } from './thunks';
import { redirectToLoginPage } from '../../utils/user';

interface UserData {
  id: string;
  store: string;
}

interface InitialState {
  currentUser: {
    data: UserData | null;
    loading: boolean;
    error: any;
  };
  settings?: Record<string, any>;
}

const initialState: InitialState = {
  currentUser: { data: null, loading: false, error: null },
  settings: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.currentUser.loading = true;
      state.currentUser.error = null;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      const user = state.currentUser.data;
      if (user) {
        if (user.id !== action.payload.id || user.store !== action.payload.store) {
          window.location.reload();
        }
      }

      state.currentUser.loading = false;
      state.currentUser.data = {
        id: action.payload.id,
        store: action.payload.store,
      };
    });
    builder.addCase(getUser.rejected, () => {
      redirectToLoginPage();
    });
    builder
      .addCase(getUserSettings.fulfilled, (state, action) => {
        state.settings = action.payload;
      });
  },
});

export default slice.reducer;
