import { createSlice } from '@reduxjs/toolkit';
import { getCalendarSlots } from './thunks';
import { TimeHelper } from '../../utils';

interface InitialState {
  loading: boolean;
  error: any;
  date?: string;
  data: {};
  lastTimestamp: number;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  data: {},
  lastTimestamp: 0,
};

const slice = createSlice({
  name: 'calendarSlots',
  initialState,
  reducers: {
    resetCalendarSlots: (state) => {
      // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-unused-vars
      state = initialState;
    },
    syncSlots: (state, action) => {
      const { data: slotsPayload, timezone } = action.payload;
      const newSlots = {};
      slotsPayload.create.forEach((slot) => {
        const { date, employeeId } = slot;
        if (!newSlots[date]) {
          newSlots[date] = {};
        }
        if (!newSlots[date][employeeId]) {
          newSlots[date][employeeId] = [];
        }

        const dateTime = TimeHelper.dateAndTimeToDateAndTimeTz(date, slot.time, timezone);

        newSlots[date][employeeId].push({
          ...slot,
          time: dateTime.time,
        });
      });
      state.data = newSlots;
    },
    deleteSlot: (state, action) => {
      const slotPayload = action.payload;
      const { date, employeeId } = slotPayload;
      if (!state.data[date]) {
        return;
      }
      if (!state.data[date][employeeId]) {
        return;
      }
      state.data[date][employeeId] = state.data[date][employeeId].filter((slot) => slot.id !== slotPayload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCalendarSlots.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.lastTimestamp = action.meta.arg.timestamp;
    });
    builder.addCase(getCalendarSlots.fulfilled, (state, action) => {
      if (state.lastTimestamp <= action.payload.timestamp) {
        state.loading = false;
        state.error = null;
        state.data = { ...action.payload.data.slots };
      }
    });
    builder.addCase(getCalendarSlots.rejected, (state, action) => {
      if (state.lastTimestamp <= action.payload!.timestamp) {
        state.loading = false;
        state.error = action.error; // todo check it
      }
    });
  },
});

export const { deleteSlot, resetCalendarSlots, syncSlots } = slice.actions;
export default slice.reducer;
