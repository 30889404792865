import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { CircularProgress } from '@mui/material';

import styles from './EventsOverview.module.scss';
import { DayOverviewType } from '../../../types';
import { TimeHelper } from '../../../utils';
import { CalendarDay } from '../subcomponents';
import { useAppSelector } from '../../../store/hooks';
import { selectCurrentStore } from '../../../store/store/selectors';

type Props = {
  currentDate: Dayjs;
  scrollDate: Dayjs;
  data: Record<string, DayOverviewType>;
  isLoading: { prev: boolean; next: boolean };
  onScroll: (isNext: boolean) => void;
};

export function EventsOverview(props: Props) {
  const { currentDate, data, scrollDate, isLoading, onScroll } = props;

  const store = useAppSelector(selectCurrentStore);

  const observerTargetNext = useRef(null);
  const observerTargetPrev = useRef(null);
  const currentElement = useRef(null);

  const [isInitialSetup, setIsInitialSetup] = useState(true);

  const createObserver = (observerTarget, handleScroll: (isNext: boolean) => void, isNext: boolean) => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0] && entries[0].isIntersecting && !isInitialSetup) {
          handleScroll(isNext);
        }
      },
      { threshold: 0.5 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  };

  useEffect(() => {
    const cleanupPrev = createObserver(observerTargetPrev, onScroll, false);
    const cleanupNext = createObserver(observerTargetNext, onScroll, true);
    setIsInitialSetup(false);

    return () => {
      cleanupPrev();
      cleanupNext();
    };
  }, [onScroll, observerTargetPrev, observerTargetNext]);

  const scrollTarget = useMemo(() => {
    const scrollStr = TimeHelper.toStandardFormat(scrollDate);
    return scrollStr;
  }, [scrollDate, currentDate]);

  useEffect(() => {
    if (currentElement.current && scrollTarget) {
      (currentElement.current as HTMLElement).scrollIntoView(true);
    }
  }, [currentElement, scrollTarget, data]);

  const days = useMemo(
    () => (
      Object.values(data)
        .sort((a, b) => a.date - b.date)
        .map((dayData) => TimeHelper.toDayjs(dayData.date, store.timezone))
    ),
    [data, store],
  );

  const weekDays = useMemo(() => {
    const result = dayjs.localeData().weekdaysShort();

    const elements = result.splice(0, dayjs.localeData().firstDayOfWeek());
    result.push(...elements);

    return result;
  }, []);

  return (
    <div className={styles.calendar}>
      <div className={styles.calendar_wrapper}>
        <div className={styles.calendar_content}>
          {weekDays.map((day) => (
            <div key={day} className={styles.day_title}>
              {day}
            </div>
          ))}
          <div ref={observerTargetPrev} className={styles.loading}>
            {isLoading.prev && <CircularProgress disableShrink />}
          </div>
          {days.map((day) => {
            const dayStr = TimeHelper.toStandardFormat(day);
            return (
              <div key={dayStr} className={styles.day_content} ref={dayStr === scrollTarget ? currentElement : null}>
                <CalendarDay date={day} data={data[dayStr]} currentDate={currentDate} />
              </div>
            );
          })}
          <div ref={observerTargetNext} className={styles.loading}>
            {isLoading.next && <CircularProgress disableShrink />}
          </div>
        </div>
      </div>
    </div>
  );
}
