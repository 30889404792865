import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Stack, Typography } from '@mui/material';
import ReactGA from 'react-ga4';

import { Header as TopHeader, MenuButton, Nullable } from '@linetweet/linetweet-ui';
import { IMenuLocaleSelectContext } from '@linetweet/linetweet-ui/components/Header/types';

import { Reload } from 'assets/icons';
import { useLanguageOptions } from '../commons';
import { BreaksResyncProps, EpepResyncDialog } from './components/ResyncDialog';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getLogoutUrl, getSwitchProfileUrl, TimeHelper } from '../../utils';
import { resyncEpep } from '../../store/epep/thunks';
import { selectEpepState } from '../../store/epep/selectors';
import styles from './Header.module.scss';
import { selectHealthState } from '../../store/health/selectors';
import { syncBreaksToDiwa } from '../../store/appointments/thunks';
import { LocaleSelectContext } from '../../layouts/LocaleProvider/LocaleProvider';
import { CALENDAR_FILTERS_LOCAL_STORAGE } from '../AppointmentsCalendar/constants';
import { selectCurrentStore } from '../../store/store/selectors';

const EPEP_TIMER = 60 * 60;

export function Header() {
  const intl = useIntl();
  const userSettings = useAppSelector((state) => state.user.settings);
  const store = useAppSelector(selectCurrentStore);
  const epepState = useAppSelector(selectEpepState);
  const appointmentsState = useAppSelector((state) => state.appointments);
  const healthState = useAppSelector(selectHealthState);

  const localeContext = useContext(LocaleSelectContext);
  if (!localeContext) {
    throw Error('LocaleSelectContext not configured');
  }

  const dispatch = useAppDispatch();

  const [appVersion, setAppVersion] = useState<Nullable<{ version: string; date: string }>>(null);
  const [epepDialogOpen, setEpepDialogOpen] = useState(false);
  const [epepTimer, setEpepTimer] = useState<number>(() => {
    const saved = localStorage.getItem('epepTimer');
    if (!saved) {
      return 0;
    }
    const startTime = parseInt(saved, 10);
    const currentTime = Date.now();
    const diff = Math.floor((currentTime - startTime) / 1000);
    if (diff > EPEP_TIMER) {
      return 0;
    }
    return EPEP_TIMER - diff;
  });
  const [breaksDialogOpen, setBreaksDialogOpen] = useState(false);
  const [epepDelayTimer, setEpepDelayTimer] = useState<number | null>(null);
  const [showSyncingEpepTitle, setShowSyncingEpepTitle] = useState<boolean>(false);

  // TODO: replace VT with real user initials from logged user
  const [userName] = useState('VT');

  const languageOptions = useLanguageOptions.default();

  const title = store.name;

  const syncEpepAndShowTitleAfterDelay = () => {
    dispatch(resyncEpep(store.id));
    setShowSyncingEpepTitle(true);
    setTimeout(() => {
      setShowSyncingEpepTitle(false);
    }, 10000);
  };

  useEffect(() => {
    const savedTime = localStorage.getItem('epepDelayTimer');
    if (savedTime) {
      const timeLeft = parseInt(savedTime, 10) - Date.now();
      if (timeLeft > 0) {
        setEpepDelayTimer(timeLeft);
        const interval = setInterval(() => {
          setEpepDelayTimer((prevTime) => {
            const newTime = prevTime! - 1000;
            if (newTime <= 0) {
              clearInterval(interval);
              syncEpepAndShowTitleAfterDelay();
            }
            return newTime > 0 ? newTime : 0;
          });
        }, 1000);
        return () => clearInterval(interval);
      }
      localStorage.removeItem('epepDelayTimer');
    }

    return undefined;
  }, []);

  const startEpepDelayTimer = (): void => {
    const timerDuration = (store.settings.syncDelay || 0) * 1000;
    if (!timerDuration) {
      dispatch(resyncEpep(store.id));
      return;
    }
    const startTime = Date.now();
    localStorage.setItem('epepDelayTimer', (startTime + timerDuration).toString());
    setEpepDelayTimer(timerDuration);
    const interval = setInterval(() => {
      setEpepDelayTimer((prevTime) => {
        const newTime = prevTime! - 1000;
        if (newTime <= 0) {
          clearInterval(interval);
          syncEpepAndShowTitleAfterDelay();
        }
        return newTime > 0 ? newTime : 0;
      });
    }, 1000);
  };

  useEffect(() => {
    if (healthState.loading) {
      return;
    }
    if (healthState.versionData) {
      const { version, date } = healthState.versionData;
      const versionDate = TimeHelper.toDayjs(date, store.timezone).format('DD.MM.YYYY HH:mm');
      setAppVersion({ version, date: versionDate });
    }
  }, [store, healthState]);

  const signOut = useCallback(async () => {
    localStorage.removeItem(CALENDAR_FILTERS_LOCAL_STORAGE);
    window.location.href = getLogoutUrl();
  }, []);

  const switchProfile = useCallback(async () => {
    window.location.href = getSwitchProfileUrl();
  }, []);

  const urls = userSettings?.urls || {};

  const onEpepDialogSubmit = useCallback(async () => {
    setEpepTimer(EPEP_TIMER);
    const currentTime = Date.now().toString();
    localStorage.setItem('epepTimer', currentTime);
    setEpepDialogOpen(false);
    startEpepDelayTimer();
  }, [store]);

  const onBreaksDialogSubmit = useCallback(async () => {
    const date = TimeHelper.toDayjs(new Date(), store.timezone);
    await dispatch(syncBreaksToDiwa({ storeId: store.id, date }));
    setBreaksDialogOpen(false);
  }, [store]);

  useEffect(() => {
    if (epepTimer === 0) {
      if (localStorage.getItem('epepTimer')) {
        localStorage.removeItem('epepTimer');
      }
      return;
    }
    const intervalId = setInterval(() => {
      const updatedValue = epepTimer - 1;
      setEpepTimer(updatedValue);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [epepTimer]);

  const epepTimerFormatted = useMemo(() => {
    if (epepTimer === 0) {
      return '';
    }
    const minutes = epepTimer > 60 ? Math.floor(epepTimer / 60) : '';
    const seconds = epepTimer % 60;
    const secondsWithZero = seconds < 10 && minutes ? `0${seconds}` : seconds;
    if (minutes) {
      return `${minutes}:${secondsWithZero}`;
    }
    return seconds === 0 ? '1:00' : seconds;
  }, [epepTimer]);

  return (
    <>
      <TopHeader
        title={title}
        styleClass="performance-coach"
        clock={{ show: false }}
        timeZone={store.timezone}
        profile={{
          show: true,
          userName,
          urls: {
            diwa: urls?.operations,
            booking: urls?.appointments,
            performance: urls?.performanceTracker,
          },
          currentlySelected: 'appointment',
        }}
        isAppointmentsAvailable
        {...(epepDelayTimer || showSyncingEpepTitle
          ? {
              warning: {
                message: intl.formatMessage(
                  { id: showSyncingEpepTitle ? 'common.epepSyncInProcess' : 'common.epepSyncDelay' },
                  { delay: Math.floor((epepDelayTimer || 0) / 1000), source: store.settings?.employeeSource },
                ),
                sticky: true,
              },
            }
          : {})}
        menu={{
          extra: (
            <>
              <Stack direction="row" className={epepTimer > 0 ? styles.epepWithTimer : styles.epepWithoutTimer}>
                <MenuButton
                  icon={<Reload className={styles.reloadEpepIcon} />}
                  label={intl.formatMessage({ id: 'menu.epepSync' }, { source: store.settings?.employeeSource })}
                  description={intl.formatMessage(
                    {
                      id: intl.messages[`menu.${store.settings.employeeSource}SyncDescription`]
                        ? `menu.${store.settings.employeeSource}SyncDescription`
                        : 'menu.epepSyncDescription',
                    },
                    { source: store.settings?.employeeSource },
                  )}
                  onClick={() => {
                    ReactGA.event({
                      category: 'EPEP',
                      action: 'Resync',
                    });
                    setEpepDialogOpen(true);
                  }}
                />
                <Typography>{epepTimerFormatted}</Typography>
              </Stack>
              <MenuButton
                icon={<Reload className={styles.reloadBreaksIcon} />}
                label={intl.formatMessage({ id: 'menu.breaksSync' })}
                description={intl.formatMessage({ id: 'menu.breaksSyncDescription' })}
                onClick={() => setBreaksDialogOpen(true)}
              />
            </>
          ),
          localeContext: localeContext as IMenuLocaleSelectContext,
          signOut,
          languageOptions,
          onSwitchProfile: switchProfile,
          appVersion: appVersion || undefined,
          isOnline: true,
          employeeSettingsLink: {
            show: false,
          },
          settingsLink: {
            show: !!userSettings?.storeSettingsEnabled,
            link: urls?.settingsPage,
          },
          showOnline: false,
          showSwitchProfile: userSettings?.canSelectProfile || false,
          showLogout: true,
          showContacts: false,
          showLocaleSelector: true,
          translations: {
            menu: 'common.defaultMenuTitle',
            store: 'common.defaultStoreSettings',
          },
          bottomLink: {
            show: true,
            text: 'Acknowledgement',
            link: '/acknowledgement',
          },
        }}
      />

      <EpepResyncDialog
        open={epepDialogOpen}
        onClose={() => setEpepDialogOpen(false)}
        onSubmit={onEpepDialogSubmit}
        submitting={epepState.loading}
        employeeSource={store.settings?.employeeSource}
      />
      <BreaksResyncProps
        open={breaksDialogOpen}
        onClose={() => setBreaksDialogOpen(false)}
        onSubmit={onBreaksDialogSubmit}
        submitting={appointmentsState.loading}
      />
    </>
  );
}
